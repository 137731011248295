// Used by DateInput and DateRangeInput
import React, { useCallback, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import lodash from 'lodash';

import ChevronLeftIcon from 'shared/designTokens/icons/ui/small/ChevronLeftIcon';
import ChevronRightIcon from 'shared/designTokens/icons/ui/small/ChevronRightIcon';

import useToday from 'app/src/hooks/useToday';
import { smallMedia, dayMonthYearFormat } from 'shared/vars';
import _ from 'shared/copy';

const Container = styled.div`
	position: relative;
	width: 100%;
	padding: 24px;
	min-height: 296px;
	margin: 0 auto;

	${smallMedia} {
		width: 300px;
		margin: 0:
	}
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	text-align: center;
	font-size: 16px;
	line-height: 24px;
	color: ${props => props.theme.grey1};
	margin-bottom: 8px;
`;

const StyledChevronLeft = styled(ChevronLeftIcon)`
	cursor: pointer;

	path {
		fill: ${props => props.theme.grey5};
	}

	&:hover path {
		fill: ${props => props.theme.pokiHover};
	}
`;

const StyledChevronRight = styled(ChevronRightIcon)`
	cursor: pointer;

	path {
		fill: ${props => props.theme.grey5};
	}

	&:hover	path {
		fill: ${props => props.theme.pokiHover};
	}
`;

const Title = styled.div`
	flex-grow: 1;

	${props => props.active && `
	cursor: pointer;

	&:hover {
		color: ${props.theme.pokiHover};
	}
	`}
`;

const DateRow = styled.div`
	display: flex;
	justify-content: center;
`;

const WeekDay = styled.div`
	display: flex;
	width: 36px;
	height: 36px;
	align-items: center;
	justify-content: center;
	color: ${props => props.theme.grey3};
	text-transform: uppercase;
	font-size: 12px;
	line-height: 16px;
	font-weight: bold;
`;

const DayInner = styled.div`
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Day = styled.div`
	position: relative;
	display: flex;
	width: 28px;
	height: 28px;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 18px;
	cursor: pointer;
	margin: 4px;

	${props => (props.isSelected ? `
	${DayInner} {
		background: ${props.theme.pokiBlue};
		color: ${props.theme.static.pureWhite};
	}
	` : (!props.disabled && `
	&:hover {
		${DayInner} {
			color: ${props.theme.pokiBlue};
		}
	}
	`))}

	${props => (props.thisMonth ? `
	color: ${props.theme.grey3};
	` : `
	color: ${props.theme.grey5};
	`)}

	${props => props.isToday && `
	font-weight: bold;
	`}

	${props => (props.isInRange && !props.isSelected && `
	background: ${props.theme.grey7};
	border-radius: 0;
	margin-right: 0;
	margin-left: 0;
	width: 36px;
	`)}

	${props => props.touchesRange && `
	&::after {
		content: '';
		background: ${props.theme.grey7};
		position: absolute;
		height: 100%;
		width: 20px;

		${props.touchesRange === 'right' ? `
		right: -4px;
		` : `
		left: -4px;
		`}
	}
	`}

	${props => props.disabled && `
	color: ${props.theme.grey5};
	cursor: not-allowed;
	`}
`;

const Month = styled.div`
	display: flex;
	width: 63px;
	height: 36px;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	line-height: 18px;
	cursor: pointer;

	&:hover {
		color: ${props => props.theme.pokiHover};
	}

	${props => props.isToday && `
	font-weight: bold;
	`}
`;

const Year = styled.div`
	display: flex;
	width: 63px;
	height: 36px;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	line-height: 18px;
	cursor: pointer;

	&:hover {
		color: ${props => props.theme.pokiHover};
	}

	${props => props.isToday && `
	font-weight: bold;
	`}
`;

const DatePicker = props => {
	const { moment: inputMoment, onSet, isRange = false, minMoment, maxMoment } = props;

	const yearRows = 5;
	const yearCols = 4;
	const totalYears = yearRows * yearCols;

	const today = useToday();
	const [selectedMom, setSelectedMom] = useState(isRange ? inputMoment?.map(mom => (mom ? mom.startOf('day') : null)) : inputMoment?.startOf('day'));
	const [activeMom, setActiveMom] = useState((isRange ? selectedMom?.[0] : selectedMom) || moment(today));
	const [zoomLevel, setZoomLevel] = useState('day');
	const [currentlyPicking, setCurrentlyPicking] = useState('start');
	const [dayHover, setDayHover] = useState(null);

	const handleTitleClick = () => {
		if (zoomLevel === 'day') {
			setZoomLevel('month');
		} else if (zoomLevel === 'month') {
			setZoomLevel('year');
		}
	};

	const handleChevronLeftClick = () => {
		if (zoomLevel === 'day') {
			setActiveMom(moment(activeMom).subtract(1, 'month'));
		} else if (zoomLevel === 'month') {
			setActiveMom(moment(activeMom).subtract(1, 'year'));
		} else if (zoomLevel === 'year') {
			setActiveMom(moment(activeMom).subtract(totalYears, 'year'));
		}
	};

	const handleChevronRightClick = () => {
		if (zoomLevel === 'day') {
			setActiveMom(moment(activeMom).add(1, 'month'));
		} else if (zoomLevel === 'month') {
			setActiveMom(moment(activeMom).add(1, 'year'));
		} else if (zoomLevel === 'year') {
			setActiveMom(moment(activeMom).add(totalYears, 'year'));
		}
	};

	const createHandleClickMonth = month => () => {
		setActiveMom(month);
		setZoomLevel('day');
	};

	const createHandleClickYear = year => () => {
		setActiveMom(moment(activeMom).set('year', year));
		setZoomLevel('month');
	};

	const createHandleDayMouseEnter = day => () => {
		setDayHover(day);
	};

	const createHandleClickDay = useCallback(day => () => {
		if (isRange) {
			let newValue = selectedMom;
			if (currentlyPicking === 'start') {
				newValue = [day, null];
				setCurrentlyPicking('end');
			} else {
				if (day.isBefore(selectedMom[0])) {
					newValue = [day, selectedMom[0]];
				} else {
					newValue = [selectedMom[0], day];
				}

				setCurrentlyPicking('start');
			}

			setSelectedMom(newValue);
			if (onSet) onSet(newValue);
		} else {
			if (onSet) onSet(day.format(dayMonthYearFormat));

			setSelectedMom(day);
			setActiveMom(day);
		}

		setDayHover(null);
	}, [selectedMom]);

	const startOfYear = moment(activeMom).startOf('year');
	const months = Array(12).fill(0).map((__, idx) => idx);

	const startOfWeek = moment(activeMom).startOf('week');
	const weekDays = Array(7).fill(0).map((__, idx) => idx);

	const startOfMonth = moment(activeMom).startOf('month');
	const endOfMonth = moment(activeMom).endOf('month');
	const startOfMonthDaysSinceSunday = startOfMonth.day();

	const days = [];
	if (zoomLevel === 'day') {
		for (let idx = startOfMonthDaysSinceSunday; idx > 0; idx--) {
			days.push(moment(startOfMonth).subtract(idx, 'days'));
		}

		for (let idx = 0; idx < activeMom.daysInMonth(); idx++) {
			days.push(moment(startOfMonth).add(idx, 'days'));
		}

		for (let idx = 1; idx < (days.length % 7) + 1; idx++) {
			days.push(moment(endOfMonth).add(idx, 'days'));
		}
	}

	const years = [];
	if (zoomLevel === 'year') {
		const startYearChunk = activeMom.year() - (activeMom.year() % totalYears);

		for (let idx = 0; idx < totalYears; idx++) {
			years.push(startYearChunk + idx);
		}
	}

	return (
		<Container>
			<Header>
				<StyledChevronLeft onClick={handleChevronLeftClick} />
				<Title active={zoomLevel !== 'year'} onClick={handleTitleClick}>
					{zoomLevel === 'day' ? (
						activeMom.format('MMMM gggg')
					) : zoomLevel === 'month' ? (
						activeMom.format('gggg')
					) : (
						_`selectYear`
					)}
				</Title>
				<StyledChevronRight onClick={handleChevronRightClick} />
			</Header>
			{zoomLevel === 'day' ? (
				<>
					<DateRow>{weekDays.map(day => <WeekDay key={`weekday-${day}`}>{moment(startOfWeek).add(day, 'day').format('ddd')}</WeekDay>)}</DateRow>
					{lodash.chunk(days, 7).map(chunk => (
						<DateRow key={`day-row-${chunk[0].format('MMDD')}`}>
							{chunk.map(day => {
								const disabled = (minMoment && day.isBefore(minMoment)) || (maxMoment && day.isAfter(maxMoment));
								const isSelected = isRange ? day.isSame(selectedMom?.[0], 'day') || day.isSame(selectedMom?.[1], 'day') : day.isSame(selectedMom, 'day');

								let touchesRange = false;
								if (isRange && isSelected) {
									if (selectedMom?.[1] && !selectedMom?.[0].isSame(selectedMom?.[1])) {
										if (day.isSame(selectedMom?.[0], 'day')) {
											touchesRange = 'right';
										} else {
											touchesRange = 'left';
										}
									} else if (!selectedMom?.[1] && dayHover) {
										if (dayHover.isAfter(selectedMom?.[0])) {
											touchesRange = 'right';
										} else {
											touchesRange = 'left';
										}
									}
								}

								let isInRange = false;
								if (isRange) {
									isInRange = day.isSameOrBefore(selectedMom?.[1]) && day.isSameOrAfter(selectedMom?.[0] || dayHover);
									isInRange = isInRange || (selectedMom?.[0] && !selectedMom?.[1] && dayHover && (day.isBetween(selectedMom?.[0], dayHover, undefined, '[]') || day.isBetween(dayHover, selectedMom?.[0], undefined, '[]')));
								}

								return (
									<Day
										key={`day-${day.format('D')}`}
										thisMonth={day.isSameOrAfter(startOfMonth) && day.isBefore(endOfMonth)}
										isToday={day.isSame(today, 'day')}
										isInRange={isInRange}
										isSelected={isSelected}
										touchesRange={touchesRange}
										disabled={disabled}
										onClick={disabled ? undefined : createHandleClickDay(day)}
										onMouseEnter={createHandleDayMouseEnter(day)}
									>
										<DayInner>{day.format('D')}</DayInner>
									</Day>
								);
							})}
						</DateRow>
					))}
				</>
			) : zoomLevel === 'month' ? (
				<>
					{lodash.chunk(months, 4).map(chunk => (
						<DateRow key={`month-row-${chunk[0]}`}>
							{chunk.map(monthIdx => {
								const month = moment(startOfYear).add(monthIdx, 'month');
								return (
									<Month
										key={`month-${monthIdx}`}
										isToday={month.isSame(today, 'month')}
										onClick={createHandleClickMonth(month)}
									>
										{month.format('MMM')}
									</Month>
								);
							})}
						</DateRow>
					))}
				</>
			) : (
				<>
					{lodash.chunk(years, yearRows).map(chunk => (
						<DateRow key={`year-row-${chunk[0]}`}>
							{chunk.map(year => (
								<Year
									key={`year-${year}`}
									isToday={year === today.year()}
									onClick={createHandleClickYear(year)}
								>
									{year}
								</Year>
							))}
						</DateRow>
					))}
				</>
			)}
		</Container>
	);
};

export default DatePicker;
