import { merge, of, map, tap, ignoreElements, switchMap, delay } from 'rxjs';
import { ofType } from 'redux-observable';
import { createApiEpic } from '@poki/rx-api';

import history from 'app/history';
import getApiUrl from 'app/src/utils/getApiUrl';
import dataFormatter from 'app/src/utils/dataFormatter';
import { signoutWithNewEmail, setAccessToken, setRefreshToken, setPreviousRefreshToken } from 'app/src/actions/session';
import { createAuthorizedApiEpic } from 'app/src/utils/api';
import { openModal } from 'app/src/actions/client';
import { selectRefreshToken } from 'app/src/selectors/session';
import { domain } from 'shared/vars';

export const retrieveUserDetails = createAuthorizedApiEpic(
	'user/retrieve_user_details',
	callApi => callApi({
		url: getApiUrl('devs', 'users/@me'),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
	({ error$ }) => merge(
		error$.pipe(
			switchMap(({ payload: { options = {} } }) => {
				let retryCount = options.retryCount || 0;
				if (retryCount < 3) {
					retryCount++;
					return of(retrieveUserDetails.fetch({ ...options, retryCount }))
						.pipe(delay(retryCount * 500));
				}

				return of(openModal({ key: 'temp-auth-issues' }));
			}),
		),
	),
);

export const signinUsingEmail = createApiEpic(
	'user/signin_using_email',
	(callApi, email) => callApi({
		url: getApiUrl('auth', `signin/email?return=${encodeURIComponent(`${domain}/auth/email`)}&email=${encodeURIComponent(email)}&service=pokifordevs`),
		method: 'GET',
	}),
);

export const createUser = createAuthorizedApiEpic(
	'user/create',
	(callApi, { name, role, email, teamId, sendWelcomeEmail }) => callApi({
		url: getApiUrl('devs', 'users'),
		method: 'POST',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
		body: JSON.stringify({
			...(dataFormatter.serialize({
				stuff: {
					type: 'users',
					name: name.trim(),
					email,
					role,
					team: {
						type: 'teams',
						id: teamId,
					},
					relationshipNames: ['team'],
				},
			})),
			meta: {
				send_welcome_email: !!sendWelcomeEmail,
			},
		}),
	}),
);

export const emailExists = createAuthorizedApiEpic(
	'user/email-exists',
	(callApi, email) => callApi({
		url: getApiUrl('devs', 'users/@emailexists'),
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: { email },
	}),
);

export const patchUser = createAuthorizedApiEpic(
	'user/patch',
	(callApi, { userId, data: { ...data } }) => {
		if (data.name) data.name = data.name.trim();

		return callApi({
			url: getApiUrl('devs', `users/${userId}`),
			method: 'PATCH',
			headers: {
				Accept: 'application/vnd.api+json',
				'Content-Type': 'application/vnd.api+json',
			},
			body: JSON.stringify(dataFormatter.serialize({
				stuff: {
					id: userId,
					type: 'users',
					...data,
				},
			})),
		});
	},
);

export const impersonateUser = createAuthorizedApiEpic(
	'user/impersonate',
	(callApi, { refreshToken, targetId }) => callApi({
		url: getApiUrl('auth', 'impersonate'),
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: {
			refresh_token: refreshToken,
			service: 'pokifordevs',
			target: targetId,
		},
	}),
	({ success$ }) => (
		success$.pipe(
			switchMap(({ payload: { result: { response }, state$ } }) => (
				of(
					setPreviousRefreshToken({ previousRefreshToken: selectRefreshToken(state$.value) }),
					setAccessToken({ accessToken: response.access_token }),
					setRefreshToken({ refreshToken: response.refresh_token }),
				)
			)),
			tap(() => {
				setTimeout(() => (
					window.location.reload()
				), 0);
			}),
		)
	),
);

export const signoutWithNewEmailEpic = action$ => (
	action$.pipe(
		ofType(signoutWithNewEmail.type),
		map(({ email }) => signinUsingEmail.fetch(email, ({ success$ }) => (
			success$.pipe(
				tap(() => {
					history.push(`/signin?waitingForEmail&email=${email}`);
				}),
				ignoreElements(),
			)
		))),
	)
);
