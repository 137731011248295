import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Card from 'app/src/components/ui/Card';
import Stat from 'app/src/components/ui/Stat';
import { getQuickStatsForTeam } from 'app/src/epics/data';
import { useSelectActiveTeam, useSelectTeamCurrency } from 'app/src/selectors/team';
import { useSelectDataByEpic } from 'app/src/selectors/data';
import { mediumMedia, isMobile, largePadding, smallPadding } from 'shared/vars';

const Container = styled.div`
	display: flex;
	gap: 16px;
	padding: 0 ${largePadding}px 24px;
    margin: 0 -${largePadding}px;

	${mediumMedia} {
		gap: 24px;
	}

	${isMobile && `
	width: calc(100% + ${smallPadding * 2}px);
	padding: 0 ${smallPadding}px ${smallPadding}px;
	margin: 0 -${smallPadding}px;
	overflow-x: scroll;
	`}

	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 0;
		height: 0;
		scrollbar-width: none;
		scrollbar-height: none;
	}
`;

const StyledCard = styled(Card)`
	width: 100%;
	min-width: fit-content;
`;

const Timeframe = styled.div`
	text-transform: capitalize;
	font-size: 14px;
	color: ${props => props.theme.grey3};
	font-weight: bold;
	white-space: nowrap;
	margin-bottom: 8px;
`;

const QuickStats = () => {
	const dispatch = useDispatch();
	const activeTeam = useSelectActiveTeam();
	const teamCurrency = useSelectTeamCurrency(activeTeam);

	useEffect(() => {
		if (!activeTeam) return;

		dispatch(getQuickStatsForTeam.fetch({ teamId: activeTeam.id, currency: teamCurrency }));
	}, [activeTeam]);

	const quickStats = useSelectDataByEpic(getQuickStatsForTeam.id).rows?.[0];

	const timeframes = [
		{
			field: 'yesterday_developer_earnings',
			name: 'Yesterday',
		},
		{
			field: 'last_7_days_developer_earnings',
			name: 'Last 7 Days',
		},
		{
			field: 'last_14_days_developer_earnings',
			name: 'Last 14 Days',
		},
		{
			field: 'current_month_developer_earnings',
			name: 'Current Month',
		},
		{
			field: 'all_time_developer_earnings',
			name: 'All-time',
		},
	];

	if (!quickStats || quickStats.length === 0) return null;

	return (
		<Container>
			{timeframes.map(({ field, name }) => (
				<StyledCard key={field}>
					<Timeframe>{name}</Timeframe>
					<Stat
						hasDescription={false}
						value={quickStats[field]}
						isCurrency={teamCurrency}
						isLarge={false}
					/>
				</StyledCard>
			))}
		</Container>
	);
};

export default QuickStats;
