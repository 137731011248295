import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import CloseIcon from 'shared/designTokens/icons/ui/small/CloseIcon';
import DocumentIcon from 'shared/designTokens/icons/ui/small/DocumentIcon';

import { toggleIsDragging } from 'app/src/actions/client';
import { startScreenShake } from 'app/src/actions/effects';
import { generateZipFile } from 'app/src/utils/generateZipFile';

import _ from 'shared/copy';

const Icon = styled.div`
	width: 48px;
	height: 48px;
	background-color: ${props => props.theme.grey7};
	border-radius: 12px;
	flex-shrink: 0;
	margin-right: 16px;
	display: flex;
	align-items: center;
	justify-content: center;

	${props => props.url && `
	background-image: url("${props.url}");
	background-size: cover;
	`}
`;

const CancelButton = styled.div`
	background-color: ${props => props.theme.grey7};
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
    height: 36px;
	cursor: pointer;
	flex-shrink: 0;
	margin-left: 16px;

	[fill] {
		fill: ${props => props.theme.grey3};
	}

	&:hover {
		&& {
			background-color: ${props => props.theme.pokiBlue};
		}

		[fill] {
			fill: ${props => props.theme.pureWhite};
		}
	}
`;

const Container = styled.div`
	border: 2px dashed ${props => props.theme.grey5};
	border-radius: 12px;
	padding: 16px;
	display: flex;
	align-items: center;
	min-width: 0;
	max-width: 500px;
	cursor: pointer;

	&:hover {
		background: ${props => props.theme.grey7};
		border-color: ${props => props.theme.pokiBlue};

		${Icon} {
			background-color: ${props => props.theme.pureWhite};
		}

		${CancelButton} {
			background-color: ${props => props.theme.pureWhite};
		}
	}

	${props => props.disabled && `
	border-color: transparent !important;
	background: ${props.theme.grey7} !important;
	cursor: not-allowed;
	color: ${props.theme.grey3};
	`}

	${props => props.error && `
	border-color: ${props.theme.rose1};
	background: ${props.theme.isDarkMode ? props.theme.static.rose1 : props.theme.static.rose9};
	`}
`;

const Title = styled.div`
	font-size: 16px;
	line-height: 24px;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: auto;
	overflow-wrap: anywhere;
`;

const StyledFieldset = styled.fieldset`
	position: relative;
	min-width: 0;
	max-width: min(500px, 80vw);
	margin-bottom: 16px;
`;

const StyledLabel = styled.label`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};

	+ ${Container} {
		margin-top: 4px;
	}
`;

const Description = styled.div`
	color: ${props => props.theme.grey5};
	line-height: 1.5em;
	font-size: 0.75em;
	margin-bottom: 20px;
`;

const DropBox = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: 10px dashed ${props => props.theme.grey5};
	padding: 40px;
	text-align: center;
	background: rgba(255, 255, 255, 0.9);
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
	pointer-events: none;

	::after {
		content: 'DROP FILE HERE';
		font-weight: bold;
		letter-spacing: 2px;
		font-size: 4em;
		color: ${props => props.theme.grey5}
	}

	${props => props.visible && `
	opacity: 1;
	pointer-events: all;
	`}
`;

const HiddenInput = styled.input`
	display: none;
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;

	& + & {
		margin-top: 0;
	}
`;

const Required = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	position: absolute;
	top: 4px;
	right: 0;
`;

const FileInput = props => {
	const { name, className, description, disabled: forceDisabled, label, validator, onUpload, errors: externalErrors = [], accept, value, valueSetter, required, showImagePreview, onRemove, disableRemove, webkitdirectory = undefined, directory = undefined, multiple = false } = props;

	const dispatch = useDispatch();

	const fileInputEl = useRef();
	const dropBoxEl = useRef();
	const cancelRef = useRef();

	const [uploadProgress, setUploadProgress] = useState(null);
	const [internalErrors, setErrors] = useState([]);
	const [previewURL, setPreviewURL] = useState();
	const [isDragging, setIsDragging] = useState(false);

	const errors = [...internalErrors, ...externalErrors];
	const disabled = forceDisabled || (uploadProgress !== null && uploadProgress !== 1);

	const handleUpload = (data, newFileUpload = false) => {
		if (valueSetter) valueSetter(data ? data.file : null);
		if (onUpload) onUpload(data);

		if (newFileUpload) {
			if (fileInputEl.current && fileInputEl.current.form) {
				const inputEvent = new Event('input', { bubbles: true });
				fileInputEl.current.form.dispatchEvent(inputEvent);
			}
		}
	};

	const validate = (data, newFileUpload = false) => {
		const { file: _file } = data;
		// The following checks only need to be run when somebody selects a new file
		if (newFileUpload) {
			if (accept) {
				if (!_file.type) {
					setErrors(['Unsupported file or directory']);
					dispatch(startScreenShake());
					return;
				} else if (!accept.split(',').map(s => s.trim()).includes(_file.type)) {
					setErrors([`Unsupported file type ("${_file.type}")`]);
					dispatch(startScreenShake());
					return;
				}
			}
		}

		if (validator) {
			validator(data, err => {
				if (err) {
					setErrors([err]);
					dispatch(startScreenShake());
					return;
				}
				setErrors([]);
				handleUpload(data, newFileUpload);
			});
		} else {
			setErrors([]);
			handleUpload(data, newFileUpload);
		}
	};

	const upload = (val, newFileUpload = false) => {
		if (!val) {
			setUploadProgress(null);
			return;
		}

		const reader = new FileReader();

		reader.addEventListener('progress', e => {
			setUploadProgress(e.loaded / e.total);
		});

		reader.addEventListener('load', () => {
			setUploadProgress(1);
			if (showImagePreview) setPreviewURL(reader.result);
			validate({ file: val, base64: reader.result }, newFileUpload);
		});

		reader.readAsDataURL(val);
	};

	useEffect(() => {
		dispatch(toggleIsDragging({ isDragging }));
	}, [isDragging]);

	const handleDrag = toggle => {
		if (disabled) {
			return;
		}

		setIsDragging(toggle);
	};

	const handleDragOver = e => {
		e.preventDefault();
		handleDrag(true);
	};

	const handleDragEnd = () => {
		handleDrag(false);
	};

	const handleDrop = async e => {
		e.preventDefault();

		if (disabled) {
			return;
		}

		const [_file] = e.dataTransfer.files;
		const topLevelFolder = e.dataTransfer.items[0].webkitGetAsEntry();

		if (topLevelFolder.isDirectory) {
			// If the user drags a folder, we need to create a zip file
			const zippedFile = await generateZipFile({ file: _file, topLevelFolder });

			upload(zippedFile, true);
		}

		upload(_file, true);

		handleDrag(false);
	};

	const handleFileChange = e => {
		const [_file] = e.target.files;

		upload(_file, true);
	};

	const cancelUpload = () => {
		handleUpload(null, true);
		setPreviewURL(null);
		onRemove?.();

		fileInputEl.current.value = null;
	};

	const handleClick = e => {
		if (disabled) return;

		if (!cancelRef.current || (e.target !== cancelRef.current && !cancelRef.current.contains(e.target))) {
			fileInputEl.current.click();
		}
	};

	// Attach event handlers
	useEffect(() => {
		window.addEventListener('dragover', handleDragOver);
		window.addEventListener('dragend', handleDragEnd);
		window.addEventListener('mousemove', handleDragEnd); // Mouse move is only fired when not dragging
		window.addEventListener('drop', handleDrop);

		return () => {
			window.removeEventListener('dragover', handleDragOver);
			window.removeEventListener('dragend', handleDragEnd);
			window.removeEventListener('mousemove', handleDragEnd);
			window.removeEventListener('drop', handleDrop);
		};
	}, []);

	useEffect(() => {
		if (value instanceof File) {
			upload(value, false);
		} else if (typeof value === 'string') {
			setPreviewURL(value);
		} else {
			setPreviewURL('');
		}
	}, [value]);

	const title = value ? (typeof value === 'string' ? value : value.name.replace(/^(.*\/)/, '')) : _`dragAndDropToUpload`;
	const isCancelVisible = value && !disabled && !disableRemove;

	return (
		<StyledFieldset className={className}>
			{required && (
				<Required>{_`required`}</Required>
			)}
			<input
				type="hidden"
				name={`${name}__helper`}
				value={value ? (typeof value === 'string' ? value : value.name + value.lastModified) : ''}
			/>
			<HiddenInput ref={fileInputEl} name={name} type="file" accept={accept} onChange={handleFileChange} multiple={multiple} webkitdirectory={webkitdirectory} directory={directory} />
			<DropBox ref={dropBoxEl} visible={isDragging} />
			{label && (
				<StyledLabel htmlFor={name} onClick={handleClick}>{label}</StyledLabel>
			)}
			<Container onClick={handleClick} disabled={disabled} error={errors.length > 0}>
				<Icon url={errors.length === 0 && previewURL}>
					{(!previewURL || errors.length > 0) && (
						<DocumentIcon />
					)}
				</Icon>
				<Title>{title}</Title>
				{isCancelVisible && (
					<CancelButton ref={cancelRef} onClick={cancelUpload} title={_`remove`}>
						<CloseIcon />
					</CancelButton>
				)}
			</Container>
			{description && (
				<Description>{description}</Description>
			)}
			{errors.map(err => <Error key={`${name}-err-${err}`}>{err}</Error>)}
		</StyledFieldset>
	);
};

export default FileInput;
