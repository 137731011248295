import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useSelectGameChecklistStatus } from 'app/src/selectors/session';
import { useSelectActiveTeam } from 'app/src/selectors/team';

import ChecklistCard from 'app/src/components/ui/ChecklistCard';
import PlaytestStep1 from 'app/src/components/pages/game/checklist/PlaytestStep1';
import Level1CreateGame from 'app/src/components/pages/game/checklist/Level1CreateGame';
import PlaytestStep2 from 'app/src/components/pages/game/checklist/PlaytestStep2';
import PlaytestStep3 from 'app/src/components/pages/game/checklist/PlaytestStep3';
import PlayerFitTest from 'app/src/components/pages/game/checklist/PlayerFitTest';
import WebFitStep1 from 'app/src/components/pages/game/checklist/WebFitStep1';
import WebFitStep2 from 'app/src/components/pages/game/checklist/WebFitStep2';
import WebFitStep3 from 'app/src/components/pages/game/checklist/WebFitStep3';
import Level4Description from 'app/src/components/pages/game/checklist/Level4Description';

import { requiredPlaytestRecordingsWatched } from 'shared/vars';
import _ from 'shared/copy';

const ChecklistWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	flex: 1 0 0;
`;

const GameOverviewChecklistModule = ({ game = {} }) => {
	const [activeLevel, setActiveLevel] = useState(0);
	const [current, setCurrent] = useState(0);

	const checklistStatus = useSelectGameChecklistStatus(game?.id);
	const activeTeam = useSelectActiveTeam();

	const isGameJam = activeTeam?.customer_segment === 'game-jam';
	const isTeamVerified = activeTeam?.verified;

	const isPlaytestCompleted = checklistStatus?.playtest?.[2]?.status === 'completed';
	const isPlayerFitCompleted = checklistStatus?.playerfit?.[0]?.status === 'completed';

	useEffect(() => {
		if (game && game.id) {
			// determine the current level
			let currentLevel = 1;

			if (checklistStatus && checklistStatus.playtest !== undefined) {
				currentLevel = 1;
			}

			if (game.playtest_recordings_watched >= requiredPlaytestRecordingsWatched && isPlaytestCompleted) {
				currentLevel = 2;
			}

			if (checklistStatus && checklistStatus.playerfit !== undefined && isPlayerFitCompleted) {
				currentLevel = 3;
			}

			if (isGameJam) {
				// Game Jam games can't go past this level.
				currentLevel = 1;
			}

			setCurrent(currentLevel);
			setActiveLevel(currentLevel);
		}
	}, [game, checklistStatus, isGameJam]);

	const onClickCard = level => {
		setActiveLevel(level);
	};

	return (
		<ChecklistWrapper>
			{current === 0 && (
				<ChecklistCard
					id="create-game"
					title={_`addYourGame`}
					level="1"
					subComponent={(<Level1CreateGame />)}
					icon="upload"
					active={activeLevel === 0}
					current={current >= 0}
					onClick={() => onClickCard(0)}
				/>
			)}

			{current <= 1 && (
				<ChecklistCard
					id="playtest"
					title={_`getPlayerFeedback`}
					level="2"
					description={_`startGetPlayerFeedback`}
					checkType="coin"
					active={activeLevel === 1}
					current={current >= 1}
					game={game}
					onClick={() => onClickCard(1)}
					icon="game"
					noPadding
					steps={[
						{
							title: _`yourGamePreviewLink`,
							contentGenerator: PlaytestStep1,
						},
						{
							title: _`prepareYourGameForWeb`,
							contentGenerator: PlaytestStep2,
						},
						{
							title: _`startPlaytesting`,
							contentGenerator: PlaytestStep3,
						},
					]}
				/>
			)}

			{!isGameJam && (
				<>
					{current <= 2 && (
						<ChecklistCard
							id="playerfit"
							title="Player Fit Test"
							level="3"
							description={_`playerFitSubtitle`}
							subComponent={(<PlayerFitTest game={game} />)}
							active={activeLevel === 2}
							current={current >= 2}
							game={game}
							onClick={() => onClickCard(2)}
							icon="graph"
							steps={[]}
						/>
					)}
					{current <= 3 && (
						<ChecklistCard
							id="webfit"
							title={_`pokiWebFitTest`}
							level="4"
							description={_`feelingYourGameIsReady`}
							checkType="diamond"
							active={activeLevel === 3}
							current={current >= 3}
							game={game}
							onClick={() => onClickCard(3)}
							icon="diamond"
							steps={[
								{
									title: _`gameThumbnail`,
									contentGenerator: WebFitStep1,
								},
								{
									title: _`webFitStarts`,
									contentGenerator: WebFitStep2,
								},
								{
									title: _`webFitTestResults`,
									contentGenerator: WebFitStep3,
								},
							]}
						/>
					)}

					<ChecklistCard
						id="release-game"
						title={_`releaseYourGameOnPoki`}
						level="5"
						subComponent={(<Level4Description isTeamVerified={isTeamVerified} />)}
						active={activeLevel === 4}
						current={current >= 4}
						game={game}
						onClick={() => onClickCard(4)}
						icon="rocket"
						steps={[]}
					/>
				</>
			)}
		</ChecklistWrapper>
	);
};

export default GameOverviewChecklistModule;
