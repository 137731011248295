import { tap, ignoreElements, map, filter, take } from 'rxjs';
import moment from 'moment';
import { ofType } from 'redux-observable';

import { createAuthorizedApiEpic, sortPageFilterQuery } from 'app/src/utils/api';
import getApiUrl from 'app/src/utils/getApiUrl';
import downloadFromBase64 from 'app/src/utils/downloadFromBase64';
import { selectPermissions } from 'app/src/selectors/user';
import { retrieveUserDetails } from 'app/src/epics/user';
import { activeMonthsInP4D } from 'shared/vars';
import checkPermissions from 'app/src/utils/checkPermissions';

/**
 * Api epics
 */

export const listPendingInvoices = createAuthorizedApiEpic(
	'invoice/list_pending_invoices',
	(callApi, { sortField, sortDirection, page = 1, perPage = 1000000000, filters }) => callApi({
		url: getApiUrl('devs', `invoices?${sortPageFilterQuery({ sortField, sortDirection, page, perPage, filters: { ...filters, paid_at: 'null' } })}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
		},
	}),
);

export const listInvoicesForTeam = createAuthorizedApiEpic(
	'invoice/list_for_team',
	(callApi, { teamId, sortField, sortDirection, page = 1, perPage = 1000000000 }) => (
		callApi({
			url: getApiUrl('devs', `invoices?team=${teamId}&${sortPageFilterQuery({ sortField, sortDirection, page, perPage })}`),
			method: 'GET',
			headers: {
				Accept: 'application/vnd.api+json',
			},
		})
	),
);

export const listAllPendingInvoices = createAuthorizedApiEpic(
	'invoice/list_all_pending_invoices',
	callApi => callApi({
		url: getApiUrl('devs', `invoices?${sortPageFilterQuery({ page: 1, perPage: 1000000000, filters: { paid_at: 'null' } })}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
		},
	}),
);

export const patchInvoice = createAuthorizedApiEpic(
	'invoice/patch_invoice',
	(callApi, { invoiceId, attributes }) => callApi({
		url: getApiUrl('devs', `invoices/${invoiceId}`),
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/vnd.api+json',
		},
		body: {
			data: {
				type: 'invoices',
				id: invoiceId,
				attributes,
			},
		},
	}),
);

export const fetchPdf = createAuthorizedApiEpic(
	'invoice/fetch_pdf',
	(callApi, { invoiceId }) => callApi({
		url: getApiUrl('devs', `invoices/${invoiceId}/pdf`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
		},
	}),
);

export const downloadPdf = createAuthorizedApiEpic(
	'invoice/download_pdf',
	(callApi, { invoiceId }) => callApi({
		url: getApiUrl('devs', `invoices/${invoiceId}/pdf`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
		},
	}),
	({ success$ }) => (
		success$.pipe(
			tap(({ payload: { result: { response } } }) => {
				const { data, filename } = response.data.attributes;
				downloadFromBase64(data, filename);
			}),
			ignoreElements(),
		)
	),
);

export const sendApprovedInvoices = createAuthorizedApiEpic(
	'invoice/send_approved',
	callApi => callApi({
		url: getApiUrl('devs', 'invoices/@send'),
		method: 'POST',
	}),
);

export const getWiseStatus = createAuthorizedApiEpic(
	'invoice/wise_status',
	callApi => callApi({
		url: getApiUrl('devs', '@wise-status'),
		method: 'GET',
	}),
);

export const prepareWiseInvoices = createAuthorizedApiEpic(
	'invoice/wise-prepare',
	callApi => callApi({
		url: getApiUrl('devs', '@wise-prepare'),
		method: 'POST',
	}),
);

export const prepareSingleWiseInvoice = createAuthorizedApiEpic(
	'invoice/wise-prepare-single',
	(callApi, { invoiceId }) => callApi({
		url: getApiUrl('devs', `@wise-prepare-invoice?invoice=${invoiceId}`),
		method: 'POST',
	}),
);

export const fundWiseInvoices = createAuthorizedApiEpic(
	'invoice/wise-fund',
	callApi => callApi({
		url: getApiUrl('devs', '@wise-fund'),
		method: 'POST',
	}),
);

export const resetWiseInvoice = createAuthorizedApiEpic(
	'invoice/wise-reset',
	(callApi, { invoiceId }) => callApi({
		url: getApiUrl('devs', `@wise-reset-invoice?invoice=${invoiceId}`),
		method: 'DELETE',
	}),
);

/**
 * Other epics
 */

// Retrieves pending invoices as soon as possible, so we can
// show an indicator in our menu for how many are pending
export const retrievePendingInvoicesImmediately = (action$, state$) => (
	action$.pipe(
		ofType(retrieveUserDetails.success.type),
		filter(() => checkPermissions(selectPermissions(state$.value), [['can_edit_all_teams']])),
		map(() => listPendingInvoices.fetch()),
		take(1),
	)
);

export const listInvoicesForYearMonth = activeMonthsInP4D.reduce((result, monthMoment) => {
	const y = monthMoment.format('YYYY');
	const m = monthMoment.format('MM');

	result[`${y}-${m}`] = createAuthorizedApiEpic(
		`invoice/list_invoices_for_year_month_${y}_${m}`,
		(callApi, { year, month, sortField, sortDirection, page = 1, perPage = 1000000000, filters }) => {
			const momentFormatted = moment(`${year}-${month}-01`, 'YYYY-MM-DD');

			const after = momentFormatted.clone().startOf('month').format('YYYY-MM-DD'); // After is inclusive in the backend
			const before = momentFormatted.clone().endOf('month').add(1, 'day').format('YYYY-MM-DD'); // Before is non inclusive for in the backend

			return callApi({
				url: getApiUrl('devs', `invoices?after=${after}&before=${before}&${sortPageFilterQuery({ sortField, sortDirection, page, perPage, filters })}`),
				method: 'GET',
				headers: {
					Accept: 'application/vnd.api+json',
				},
			});
		},
	);

	return result;
}, {});

export const listAllInvoicesForYearMonth = activeMonthsInP4D.reduce((result, monthMoment) => {
	const y = monthMoment.format('YYYY');
	const m = monthMoment.format('MM');

	result[`${y}-${m}`] = createAuthorizedApiEpic(
		`invoice/list_all_invoices_for_year_month_${y}_${m}`,
		(callApi, { year, month }) => {
			const momentFormatted = moment(`${year}-${month}-01`, 'YYYY-MM-DD');

			const after = momentFormatted.clone().startOf('month').format('YYYY-MM-DD'); // After is inclusive in the backend
			const before = momentFormatted.clone().endOf('month').add(1, 'day').format('YYYY-MM-DD'); // Before is non inclusive for in the backend

			return callApi({
				url: getApiUrl('devs', `invoices?after=${after}&before=${before}&${sortPageFilterQuery({ page: 1, perPage: 1000000000 })}`),
				method: 'GET',
				headers: {
					Accept: 'application/vnd.api+json',
				},
			});
		},
	);

	return result;
}, {});
