import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { of, switchMap } from 'rxjs';
import moment from 'moment';
import styled from 'styled-components';
import lodash from 'lodash';

import GraphIcon from 'shared/designTokens/icons/ui/small/GraphIcon';

import { getGamesOverviewTrendForTeam, getGamesOverviewForTeam, getTablesLastUpdatedAt, getGamesOverviewGames } from 'app/src/epics/data';
import { useSelectDataByEpic, useSelectDataLastUpdatedAtForTable } from 'app/src/selectors/data';
import { useSelectTeamCurrency } from 'app/src/selectors/team';
import { useSelectUser } from 'app/src/selectors/user';
import useURLState from 'app/src/hooks/useURLState';
import getGameThumbnailUrl from 'app/src/utils/getGameThumbnailUrl';
import formatNumber from 'app/src/utils/formatNumber';
import formatTime from 'app/src/utils/formatTime';
import getTimeSince from 'app/src/utils/getTimeSince';
import measures from 'app/src/measures';

import TinyChart from 'app/src/components/ui/charts/TinyChart';
import Tooltip from 'shared/components/Tooltip';
import Card from 'app/src/components/ui/Card';
import Table from 'app/src/components/ui/Table';
import Link from 'app/src/components/Link';

import _, { getReleaseStatusCopy } from 'shared/copy';

const GameThumbnail = styled.div`
	position: relative;
	border-radius: 4px;
	background: ${props => (props.url ? `url("${props.url}")` : props.theme.grey7)};
	background-size: cover;
	background-position: center center;
	height: 20px;
	width: 20px;
	margin-right: 8px;
	flex-shrink: 0;
`;

const GameName = styled.div`
	display: flex;
	align-items: center;
	overflow: hidden;
`;

const Game = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const GameLink = styled(Link)`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const Data = styled.div`
	display: flex;
	flex-direction: column;
`;

const Stat = styled.div``;

const Subtitle = styled.div`
	font-size: 12px;
	color: ${props => props.theme.grey3};
`;

const StyledTooltip = styled(Tooltip)`
	border-bottom: 2px dotted ${props => props.theme.grey5};
	padding-bottom: 4px;
`;

const perPage = 100;

const GamesOverviewModule = React.memo(props => {
	const { startDate, endDate, team } = props;

	const dispatch = useDispatch();

	const [page, setPage] = useURLState('gamesOverviewPage', 1);
	const [sort, setSort] = useURLState('gamesOverviewSort', { field: 'title', direction: 1 });

	const { rows: gamesOverview, included, total: totalItems } = useSelectDataByEpic(getGamesOverviewForTeam.id);
	const { rows: gamesOverviewTrend } = useSelectDataByEpic(getGamesOverviewTrendForTeam.id);

	const getGamesOverviewStatus = useSelectApiStatus(getGamesOverviewTrendForTeam.id);

	const lastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_games_overview');
	const teamCurrency = useSelectTeamCurrency(team);

	const user = useSelectUser();
	const isAdmin = user.role === 'admin';

	useEffect(() => {
		dispatch(getTablesLastUpdatedAt.fetch());
		dispatch(getGamesOverviewGames.fetch({ teamId: team.id }));
	}, []);

	useEffect(() => {
		dispatch(getGamesOverviewForTeam.fetch({
			from: startDate,
			to: endDate,
			page,
			perPage,
			sortField: sort.field,
			sortDirection: sort.direction,
			teamId: team.id,
			currency: teamCurrency,
		}, ({ success$ }) => (
			success$.pipe(
				switchMap(({ payload: { result: { response } } }) => {
					const { rows } = response;
					const gameIds = lodash.uniq(lodash.map(rows, 'p4d_game_id'));

					return of(getGamesOverviewTrendForTeam.fetch({ gameIds, from: startDate, to: endDate, teamId: team.id, currency: teamCurrency }));
				}),
			)
		)));
	}, [sort, page, startDate, endDate]);

	const dataPerGame = useMemo(() => {
		const groupBy = ['p4d_game_id'];

		const grouped = lodash.groupBy(gamesOverviewTrend, row => groupBy.map(field => row[field]).join('-'));

		return Object.values(grouped).map(values => {
			// These columns are (should be) same for all values
			const { p4d_game_id, team_id } = values[0];

			const row = { p4d_game_id, team_id, unique_dates: [] };

			const overTimeFields = [
				{
					field: 'gameplays',
					calculate: data => data.gameplays,
				},
				{
					field: 'developer_earnings',
					calculate: data => data.developer_earnings,
				},
				{
					field: 'conversion_to_play',
					calculate: data => measures.conversion_to_play.calculate(data),
				},
				{
					field: 'time_spent_per_dau',
					calculate: data => measures.time_spent_per_dau.calculate(data),
				},
				{
					field: 'ads_per_dau',
					calculate: data => measures.ads_per_dau.calculate(data),
				},
			];

			const daysBetweenStartAndEndDate = moment(endDate).diff(moment(startDate), 'day');

			values.forEach(data => {
				const daysFromEndDate = moment(endDate).diff(moment(data.date), 'day');

				overTimeFields.forEach(({ field, calculate }) => {
					row[`${field}_over_time`] = row[`${field}_over_time`] || Array(daysBetweenStartAndEndDate).fill(0);
					if (daysFromEndDate <= daysBetweenStartAndEndDate) {
						row[`${field}_over_time`][daysBetweenStartAndEndDate - daysFromEndDate] = calculate(data);
					}
				});
			});

			return row;
		});
	}, [gamesOverviewTrend, startDate, endDate]);

	return (
		<Card
			title="Games Overview"
			noPadding
			buttons={[
				{
					id: 'games-overview-data-last-updated',
					type: 'info',
					disabled: !lastUpdatedAt,
					icon: GraphIcon,
					children: _`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(lastUpdatedAt)) }}`,
				},
			]}
		>
			<Table
				items={gamesOverview}
				isLoading={!getGamesOverviewStatus.done}
				compact
				page={page}
				perPage={perPage}
				totalItems={totalItems}
				setPagination={setPage}
				sortField={sort.field}
				sortDirection={sort.direction}
				setSort={setSort}
				columns={[
					{
						title: _`game`,
						width: 'auto',
						content: ({ item }) => {
							const game = included.games[item.p4d_game_id]?.attributes;
							const developerReleaseStatus = _([getReleaseStatusCopy(item.release_status, false, false)]);
							const realReleaseStatus = _`realStatus${{ status: _([item.release_status || 'not-released']) }}`;

							if (!game) return _`gameOrTeamNotFound`;

							return (
								<Game>
									<GameName>
										<GameThumbnail url={getGameThumbnailUrl(game.thumbnail_url, 20)} />
										<GameLink to={`/${team.code}/games/${item.p4d_game_id}`}>{game.title}</GameLink>
									</GameName>
									<Subtitle>
										{isAdmin ? (
											<StyledTooltip
												placement="top"
												arrow
												content={realReleaseStatus}
											>
												{developerReleaseStatus}
											</StyledTooltip>
										) : (
											developerReleaseStatus
										)}
									</Subtitle>
								</Game>
							);
						},
						sortField: 'title',
						defaultSortDirection: 1,
					},
					{
						title: 'GP',
						longTitle: 'Gameplays',
						width: 'max-content',
						content: ({ item }) => (
							<Data title="Gameplays">
								<Stat>
									{formatNumber(item.gameplays_total)}
								</Stat>
							</Data>
						),
						sortField: 'gameplays_total',
					},
					{
						title: 'E',
						longTitle: 'Earnings',
						width: 'max-content',
						content: ({ item }) => (
							<Data title="Earnings">
								<Stat $isZero={item.developer_earnings <= 0.00}>
									{teamCurrency === 'usd' ? '$' : '€'}{formatNumber(item.developer_earnings, { allowDecimals: true })}
								</Stat>
							</Data>
						),
						sortField: 'developer_earnings',
					},
					{
						title: 'GP/D',
						longTitle: 'Gameplays per day',
						width: 'max-content',
						content: ({ item }) => {
							const dataOverTime = dataPerGame.find(row => row.p4d_game_id === item.p4d_game_id)?.gameplays_over_time;

							return (
								<Data title="Gameplays per day">
									<Stat>
										{formatNumber(item.gameplays_per_day)}
									</Stat>
									<TinyChart
										type="area"
										width={80}
										height={15}
										data={dataOverTime}
									/>
								</Data>
							);
						},
						sortField: 'gameplays_per_day',
					},
					{
						title: 'E/D',
						longTitle: 'Earnings per day',
						width: 'max-content',
						content: ({ item }) => {
							const dataOverTime = dataPerGame.find(row => row.p4d_game_id === item.p4d_game_id)?.developer_earnings_over_time;

							return (
								<Data title="Earnings per day">
									<Stat $isZero={item.developer_earnings_per_day <= 0.00}>
										{teamCurrency === 'usd' ? '$' : '€'}{formatNumber(item.developer_earnings_per_day, { allowDecimals: true })}
									</Stat>
									<TinyChart
										type="area"
										width={80}
										height={15}
										data={dataOverTime}
									/>
								</Data>
							);
						},
						sortField: 'developer_earnings_per_day',
					},
					{
						title: 'C2P',
						longTitle: 'Conversion to play',
						width: 'max-content',
						content: ({ item }) => {
							const dataOverTime = dataPerGame.find(row => row.p4d_game_id === item.p4d_game_id)?.conversion_to_play_over_time;

							return (
								<Data title="Conversion to play">
									<Stat>
										{Math.round((item.conversion_to_play) * 100)}%
									</Stat>
									<TinyChart
										type="area"
										width={80}
										height={15}
										data={dataOverTime}
									/>
								</Data>
							);
						},
						sortField: 'conversion_to_play',
					},
					{
						title: 'TS/DAU',
						longTitle: 'Time spent per daily active user',
						width: 'max-content',
						content: ({ item }) => {
							const dataOverTime = dataPerGame.find(row => row.p4d_game_id === item.p4d_game_id)?.time_spent_per_dau_over_time;

							return (
								<Data title="Time spent per daily active user">
									<Stat>
										{formatTime(item.time_spent_per_dau)}
									</Stat>
									<TinyChart
										type="area"
										width={80}
										height={15}
										data={dataOverTime}
									/>
								</Data>
							);
						},
						sortField: 'time_spent_per_dau',
					},
					{
						title: 'ADS/DAU',
						longTitle: 'Ads per daily active user',
						width: 'max-content',
						content: ({ item }) => {
							const dataOverTime = dataPerGame.find(row => row.p4d_game_id === item.p4d_game_id)?.ads_per_dau_over_time;

							return (
								<Data title="Ads per daily active user">
									<Stat $isZero={item.ads_per_dau <= 0.00}>
										{Number(item.ads_per_dau).toFixed(2)}
									</Stat>
									<TinyChart
										type="area"
										width={80}
										height={15}
										data={dataOverTime}
									/>
								</Data>
							);
						},
						sortField: 'ads_per_dau',
					},
				]}
			/>
		</Card>
	);
});

export default GamesOverviewModule;
