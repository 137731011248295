import React, { useEffect, useState } from 'react';
import { useSelectApiStatus } from '@poki/rx-api';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import moment from 'moment';

import { listUpfrontPaymentsByTeamId, listMinimumGuaranteesByTeamId, listRecurringMonthlyPaymentsByTeamId, listOneOffPaymentsByTeamId, createMinimumGuaranteeForTeamId, createRecurringMonthlyPaymentForTeamId, createUpfrontPaymentForTeamId, createOneOffPaymentForTeamId } from 'app/src/epics/additionalPayments';
import { useSelectUpfrontPaymentsByTeamId, useSelectMinimumGuaranteesByTeamId, useSelectRecurringMonthlyPaymentsByTeamId, useSelectOneOffPaymentsByTeamId } from 'app/src/selectors/additionalPayments';
import { useSelectNewRevenueSharesByTeamId, useSelectTeamHasBillingSettings } from 'app/src/selectors/team';
import MessageBox, { MessageBoxIntents } from 'app/src/components/ui/MessageBox';
import { listUsersByTeamId, getTeamNewRevenueShares } from 'app/src/epics/team';
import { useSelectPermissions } from 'app/src/selectors/user';
import { openModal } from 'app/src/actions/client';
import useActionCounter from 'app/src/hooks/useActionCounter';
import checkPermissions from 'app/src/utils/checkPermissions';
import formatNumber from 'app/src/utils/formatNumber';
import { hasNonZeroShare } from 'app/src/utils/revenueShares';

import PlusIcon from 'shared/designTokens/icons/ui/small/PlusIcon';

import OneOffPaymentsTable from 'app/src/components/tables/OneOffPaymentsTable';
import GridContainer from 'app/src/components/ui/GridContainer';
import ProgressBar from 'app/src/components/ui/ProgressBar';
import GamesTooltip from 'app/src/components/GamesTooltip';
import Tooltip from 'app/src/components/ui/Tooltip';
import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';

import { dayMonthYearFormat } from 'shared/vars';
import _ from 'shared/copy';

const Status = styled.div`
	font-weight: bold;
	color: ${props => props.theme.grey3};

	${props => props.active && `
	color: ${props.theme.green1};
	`}
`;

const upfrontPaymentsPerPage = 10;
const minimumGuaranteesPerPage = 10;
const recurringMonthlyPaymentsPerPage = 10;
const oneOffPaymentsPerPage = 10;

const TeamSettingsAdditionalPaymentsSubPage = props => {
	const { team } = props;

	const dispatch = useDispatch();
	const location = useLocation();

	const getTeamNewRevenueSharesStatus = useSelectApiStatus(getTeamNewRevenueShares.id);
	const listUpfrontPaymentsByTeamIdStatus = useSelectApiStatus(listUpfrontPaymentsByTeamId.id);
	const listMinimumGuaranteesByTeamIdStatus = useSelectApiStatus(listMinimumGuaranteesByTeamId.id);
	const listRecurringMonthlyPaymentsByTeamIdStatus = useSelectApiStatus(listRecurringMonthlyPaymentsByTeamId.id);
	const listOneOffPaymentsByTeamIdStatus = useSelectApiStatus(listOneOffPaymentsByTeamId.id);
	const revenueShares = useSelectNewRevenueSharesByTeamId(team.id);
	const hasActiveRevenueShares = hasNonZeroShare(revenueShares);
	const teamHasBillingSettings = useSelectTeamHasBillingSettings(team);
	const permissions = useSelectPermissions();

	const { data: upfrontPayments, meta: { total: totalUpfrontPayments } = {} } = useSelectUpfrontPaymentsByTeamId(team.id);
	const { data: minimumGuarantees, meta: { total: totalMinimumGuarantees } = {} } = useSelectMinimumGuaranteesByTeamId(team.id);
	const { data: recurringMonthlyPayments, meta: { total: totalRecurringMonthlyPayments } = {} } = useSelectRecurringMonthlyPaymentsByTeamId(team.id);
	const { data: oneOffPayments, meta: { total: totalOneOffPayments } = {} } = useSelectOneOffPaymentsByTeamId(team.id);

	const [upfrontPaymentsSort, setUpfrontPaymentsSort] = useState({ field: 'start_date', direction: -1 });
	const [upfrontPaymentsPage, setUpfrontPaymentsPage] = useState(1);
	const [activeUpfrontPayments, setActiveUpfrontPayments] = useState([]);
	const [minimumGuaranteesSort, setMinimumGuaranteesSort] = useState({ field: 'start_date', direction: -1 });
	const [minimumGuaranteesPage, setMinimumGuaranteesPage] = useState(1);
	const [activeMinimumGuarantee, setActiveMinimumGuarantee] = useState([]);
	const [recurringMonthlyPaymentsSort, setRecurringMonthlyPaymentsSort] = useState({ field: 'start_date', direction: -1 });
	const [recurringMonthlyPaymentsPage, setRecurringMonthlyPaymentsPage] = useState(1);
	const [oneOffPaymentsSort, setOneOffPaymentsSort] = useState({ field: 'send_date', direction: -1 });
	const [oneOffPaymentsPage, setOneOffPaymentsPage] = useState(1);

	const hasActiveMinimumGuarantee = activeMinimumGuarantee.length > 0;
	const hasActiveUpfrontPayments = activeUpfrontPayments.length > 0;
	const canEditAllTeams = checkPermissions(permissions, [['can_edit_all_teams']]);

	const actionCounter = useActionCounter(
		createMinimumGuaranteeForTeamId.success.type,
		createRecurringMonthlyPaymentForTeamId.success.type,
		createUpfrontPaymentForTeamId.success.type,
		createOneOffPaymentForTeamId.success.type,
	);

	const calculateIsActive = item => {
		const now = moment.utc();
		const startMoment = moment.utc(item.start_date * 1000);
		const endMoment = moment.utc(item.end_date * 1000);
		const active = startMoment.isBefore(now) && (!item.end_date || endMoment.isAfter(now));

		return { now, startMoment, endMoment, active };
	};

	useEffect(() => {
		dispatch(getTeamNewRevenueShares.fetch({ teamId: team.id }));
	}, [team]);

	useEffect(() => {
		dispatch(listUsersByTeamId.fetch({ teamId: team.id }));
	}, [team, location]);

	useEffect(() => {
		dispatch(listUpfrontPaymentsByTeamId.fetch({ teamId: team.id, sortField: upfrontPaymentsSort.field, sortDirection: upfrontPaymentsSort.direction, page: upfrontPaymentsPage, perPage: upfrontPaymentsPerPage }));
	}, [actionCounter, team, location, upfrontPaymentsSort, upfrontPaymentsPage]);

	useEffect(() => {
		dispatch(listMinimumGuaranteesByTeamId.fetch({ teamId: team.id, sortField: minimumGuaranteesSort.field, sortDirection: minimumGuaranteesSort.direction, page: minimumGuaranteesPage, perPage: minimumGuaranteesPerPage }));
	}, [actionCounter, team, location, minimumGuaranteesSort, minimumGuaranteesPage]);

	useEffect(() => {
		dispatch(listRecurringMonthlyPaymentsByTeamId.fetch({ teamId: team.id, sortField: recurringMonthlyPaymentsSort.field, sortDirection: recurringMonthlyPaymentsSort.direction, page: recurringMonthlyPaymentsPage, perPage: recurringMonthlyPaymentsPerPage }));
	}, [actionCounter, team, location, recurringMonthlyPaymentsSort, recurringMonthlyPaymentsPage]);

	useEffect(() => {
		dispatch(listOneOffPaymentsByTeamId.fetch({ teamId: team.id, sortField: oneOffPaymentsSort.field, sortDirection: oneOffPaymentsSort.direction, page: oneOffPaymentsPage, perPage: oneOffPaymentsPerPage }));
	}, [actionCounter, team, location, oneOffPaymentsSort, oneOffPaymentsPage]);

	useEffect(() => {
		if (minimumGuarantees) {
			setActiveMinimumGuarantee(minimumGuarantees.map(mg => calculateIsActive(mg)).filter(i => i.active === true));
		}
	}, [minimumGuarantees]);

	useEffect(() => {
		if (upfrontPayments) {
			setActiveUpfrontPayments(upfrontPayments.filter(up => up.completed_at === null));
		}
	}, [upfrontPayments]);

	return (
		<>
			<Helmet key={`TeamSettingsAdditionalPaymentsSubPage-${team.code}`}>
				<title>Additional Payments - {team.name} - Poki for Developers</title>
			</Helmet>
			{!teamHasBillingSettings && (
				<MessageBox
					title={_`billingSettingsRequired`}
					unclosable
					intent={MessageBoxIntents.NEGATIVE}
					description={(
						<span dangerouslySetInnerHTML={{ __html: _`billingSettingsRequiredDesc` }} />
					)}
				/>
			)}
			<GridContainer cols={1}>
				<Card
					title={_`upfrontPayments`}
					noPadding
					buttons={(() => {
						if (!canEditAllTeams) {
							return;
						}

						return (
							[{
								id: 'add-upfront-payment',
								type: 'button',
								icon: PlusIcon,
								action: () => dispatch(openModal({ key: 'add-upfront-payment', data: { teamCode: team.code } })),
								disabled: !hasActiveRevenueShares || getTeamNewRevenueSharesStatus.pending || !teamHasBillingSettings || hasActiveMinimumGuarantee,
								children: _`addNew`,
							}]
						);
					})()}
				>
					<Table
						isLoading={!listUpfrontPaymentsByTeamIdStatus.done}
						items={upfrontPayments}
						setSort={setUpfrontPaymentsSort}
						sortField={upfrontPaymentsSort.field}
						sortDirection={upfrontPaymentsSort.direction}
						setPagination={setUpfrontPaymentsPage}
						page={upfrontPaymentsPage}
						perPage={upfrontPaymentsPerPage}
						totalItems={totalUpfrontPayments}
						columns={[
							{
								title: _`startDate`,
								content: ({ item }) => moment(item.start_date * 1000).format(dayMonthYearFormat),
								sortField: 'start_date',
							},
							{
								title: _`amount`,
								content: ({ item }) => {
									const amountString = `${item.currency === 'usd' ? '$' : '€'}${formatNumber(Number(item.amount / 100), { allowDecimals: true })}`;

									if (item.internal_notes) {
										return (
											<Tooltip
												text={amountString}
												title={_`internalNotes`}
												body={item.internal_notes}
											/>
										);
									}

									return amountString;
								},
								sortField: 'amount',
							},
							{
								title: _`games`,
								content: ({ item }) => <GamesTooltip games={item.games} />,
							},
							{
								title: _`progress`,
								width: 'max-content',
								content: ({ item }) => (
									<ProgressBar
										desc={item.amount_met < item.amount
											? `${item.currency === 'usd' ? '$' : '€'}${formatNumber(Number(item.amount_met / 100), { allowDecimals: true })}`
											: _`completedOn${{ date: moment(item.completed_at * 1000).format(dayMonthYearFormat) }}`}
										progress={item.amount_met / item.amount}
										// eslint-disable-next-line no-underscore-dangle
										isPaused={item._paused}
										hideProgressIfFull
									/>
								),
								sortField: 'progress',
							},
						]}
					/>
				</Card>
				<Card
					title={_`minimumGuarantees`}
					noPadding
					buttons={(() => {
						if (!canEditAllTeams) {
							return;
						}

						return (
							[{
								id: 'add-minimum-guarantee',
								type: 'button',
								icon: PlusIcon,
								action: () => dispatch(openModal({ key: 'add-minimum-guarantee', data: { teamCode: team.code, hasActiveUpfrontPayments } })),
								disabled: !hasActiveRevenueShares || getTeamNewRevenueSharesStatus.pending || !teamHasBillingSettings,
								children: _`addNew`,
							}]
						);
					})()}
				>
					<Table
						isLoading={!listMinimumGuaranteesByTeamIdStatus.done}
						items={minimumGuarantees}
						setSort={setMinimumGuaranteesSort}
						sortField={minimumGuaranteesSort.field}
						sortDirection={minimumGuaranteesSort.direction}
						setPagination={setMinimumGuaranteesPage}
						page={minimumGuaranteesPage}
						perPage={minimumGuaranteesPerPage}
						totalItems={totalMinimumGuarantees}
						columns={[
							{
								title: _`startMonth`,
								content: ({ item }) => moment.utc(item.start_date * 1000).format('MMM YYYY'),
								sortField: 'start_date',
							},
							{
								title: _`endMonth`,
								content: ({ item }) => moment.utc(item.end_date * 1000).subtract(1, 'day').format('MMM YYYY'),
								sortField: 'end_date',
							},
							{
								title: _`amount`,
								content: ({ item }) => {
									const amountString = `${item.currency === 'usd' ? '$' : '€'}${formatNumber(Number(item.monthly_amount / 100), { allowDecimals: true })}`;

									if (item.internal_notes) {
										return (
											<Tooltip
												text={amountString}
												title={_`internalNotes`}
												body={item.internal_notes}
											/>
										);
									}

									return amountString;
								},
								sortField: 'monthly_amount',
							},
							{
								title: _`status`,
								content: ({ item }) => {
									const { active, startMoment, now } = calculateIsActive(item);

									return (
										<Status active={active}>
											{active ? _`active` : startMoment.isAfter(now) ? _`notStarted` : _`expired`}
										</Status>
									);
								},
							},
						]}
					/>
				</Card>
				<Card
					title={_`recurringMonthlyPayments`}
					noPadding
					buttons={(() => {
						if (!canEditAllTeams) {
							return;
						}

						return ([
							{
								id: 'add-recurring-monthly-payment',
								type: 'button',
								icon: PlusIcon,
								action: () => dispatch(openModal({ key: 'add-recurring-monthly-payment', data: { teamCode: team.code } })),
								disabled: !teamHasBillingSettings,
								children: _`addNew`,
							},
						]);
					})()}
				>
					<Table
						isLoading={!listRecurringMonthlyPaymentsByTeamIdStatus.done}
						items={recurringMonthlyPayments}
						setSort={setRecurringMonthlyPaymentsSort}
						sortField={recurringMonthlyPaymentsSort.field}
						sortDirection={recurringMonthlyPaymentsSort.direction}
						setPagination={setRecurringMonthlyPaymentsPage}
						page={recurringMonthlyPaymentsPage}
						perPage={recurringMonthlyPaymentsPerPage}
						totalItems={totalRecurringMonthlyPayments}
						columns={[
							{
								title: _`startMonth`,
								content: ({ item }) => moment.utc(item.start_date * 1000).format('MMM YYYY'),
								sortField: 'start_date',
							},
							{
								title: _`endMonth`,
								content: ({ item }) => (item.end_date ? moment.utc(item.end_date * 1000).subtract(1, 'day').format('MMM YYYY') : '-'),
								sortField: 'end_date',
							},
							{
								title: _`amount`,
								content: ({ item }) => {
									const amountString = `${item.currency === 'usd' ? '$' : '€'}${formatNumber(Number(item.monthly_amount / 100), { allowDecimals: true })}`;

									if (item.internal_notes) {
										return (
											<Tooltip
												text={amountString}
												title={_`internalNotes`}
												body={item.internal_notes}
											/>
										);
									}

									return amountString;
								},
								sortField: 'monthly_amount',
							},
							{
								title: _`status`,
								content: ({ item }) => {
									const { active, startMoment, now } = calculateIsActive(item);

									return (
										<Status active={active}>
											{active ? _`active` : startMoment.isAfter(now) ? _`notStarted` : _`expired`}
										</Status>
									);
								},
							},
						]}
					/>
				</Card>
				<Card
					title={_`oneOffPayments`}
					noPadding
					buttons={(() => {
						if (!canEditAllTeams) {
							return;
						}

						return ([
							{
								id: 'add-one-off-payment',
								type: 'button',
								icon: PlusIcon,
								action: () => dispatch(openModal({ key: 'add-one-off-payment', data: { teamCode: team.code } })),
								disabled: !teamHasBillingSettings,
								children: _`addNew`,
							},
						]);
					})()}
				>
					<OneOffPaymentsTable
						oneOffPayments={oneOffPayments}
						isLoading={!listOneOffPaymentsByTeamIdStatus.done}
						setSort={setOneOffPaymentsSort}
						sortField={oneOffPaymentsSort.field}
						sortDirection={oneOffPaymentsSort.direction}
						setPagination={setOneOffPaymentsPage}
						page={oneOffPaymentsPage}
						perPage={oneOffPaymentsPerPage}
						totalItems={totalOneOffPayments}
					/>
				</Card>
			</GridContainer>
		</>
	);
};

export default TeamSettingsAdditionalPaymentsSubPage;
