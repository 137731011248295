import React, { useState, useCallback, useEffect } from 'react';
import { Subject, timer, takeUntil, tap, switchMap } from 'rxjs';
import { useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components';

import CloseIcon from 'shared/designTokens/icons/ui/small/CloseIcon';

import { motionSpeed02, motionOut } from 'shared/vars';
import { closeToast } from 'app/src/actions/client';

export const ToastTypes = {
	DEFAULT: 0,
	WARNING: 1,
};

const hideTime = 1000;

const appear = keyframes`
	0% {
		opacity: 0;
		transform: translateY(20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0%);
	}
`;

const OuterContainer = styled.div`
	position: absolute;
	bottom: 40px;
	left: 50%;
	width: 100%;
	max-width: 800px;
	pointer-events: none;
	display: flex;
	justify-content: center;
	transform: translateX(-50%);
	opacity: 1;

	${props => props.isHiding && `
	transition: opacity ${hideTime}ms linear;
	opacity: 0;
	`}
`;

const CloseButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 8px;
`;

const Container = styled.div`
	position: relative;
	max-height: fit-content;
	bottom: ${props => `${props.index * 52}px`};
	background: ${props => (props.type === ToastTypes.WARNING ? props.theme.rose7 : props.theme.green7)};
	color: ${props => props.theme.static.denimBlue};
	box-shadow: ${props => props.theme.boxShadowSmall};
	justify-content: center;
	align-items: center;
	font-weight: bold;
	border-radius: 8px;
	z-index: 3;
	padding: 4px 0;
	line-height: 24px;
	display: flex;
	animation: ${appear} both ${motionSpeed02} ${motionOut};
	pointer-events: all;

	svg {
		path {
			fill: ${props => (props.type === ToastTypes.WARNING ? props.theme.rose5 : props.theme.green5)};
		}
	}

	${CloseButton} {
		border-left: 1px solid ${props => (props.type === ToastTypes.WARNING ? props.theme.rose5 : props.theme.green5)};

		&:hover {
			svg {
				path {
					fill: ${props => (props.type === ToastTypes.WARNING ? props.theme.rose1 : props.theme.green1)};
				}
			}
		}
	}
`;

const Body = styled.div`
	padding: 0 24px;
`;

const Toast = props => {
	const { index, className, body, type = ToastTypes.DEFAULT } = props;

	const [isHiding, setIsHiding] = useState(false);
	const dispatch = useDispatch();

	const [stopHideTimers$] = useState(
		new Subject().pipe(
			tap(() => setIsHiding(false)),
		),
	);
	const startHideTimer = useCallback((delay = 3000) => {
		timer(delay).pipe(
			tap(() => setIsHiding(true)),
			switchMap(() => (
				timer(hideTime).pipe(
					tap(() => {
						dispatch(closeToast({ index }));
					}),
				)
			)),
			takeUntil(stopHideTimers$),
		).subscribe();
	});

	const handleCloseButtonClick = () => {
		dispatch(closeToast({ index }));
	};

	useEffect(() => {
		const sub = stopHideTimers$.subscribe();
		startHideTimer();

		return () => {
			stopHideTimers$.next();
			sub.unsubscribe();
		};
	}, []);

	return (
		<OuterContainer
			isHiding={isHiding}
		>
			<Container
				className={className}
				index={index}
				onMouseEnter={() => stopHideTimers$.next()}
				onMouseOut={() => startHideTimer(2000)}
				type={type}
			>
				<Body>{body}</Body>
				<CloseButton onClick={handleCloseButtonClick}>
					<CloseIcon />
				</CloseButton>
			</Container>
		</OuterContainer>
	);
};

export default Toast;
