import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { EMPTY, of, switchMap } from 'rxjs';
import moment from 'moment';
import styled from 'styled-components';
import lodash from 'lodash';

import GraphIcon from 'shared/designTokens/icons/ui/small/GraphIcon';

import { getGamesOverviewTrend, getGamesOverviewFull, getTeamsOverviewTeams, getTablesLastUpdatedAt, getGamesOverviewGames } from 'app/src/epics/data';
import { useSelectDataByEpic, useSelectDataLastUpdatedAtForTable } from 'app/src/selectors/data';
import useURLState from 'app/src/hooks/useURLState';
import getTimeSince from 'app/src/utils/getTimeSince';
import getGameThumbnailUrl from 'app/src/utils/getGameThumbnailUrl';
import formatNumber from 'app/src/utils/formatNumber';
import formatTime from 'app/src/utils/formatTime';
import measures from 'app/src/measures';

import Link from 'app/src/components/Link';
import TinyChart from 'app/src/components/ui/charts/TinyChart';
import Card from 'app/src/components/ui/Card';
import Table from 'app/src/components/ui/Table';
import TeamNameWithFlag from 'app/src/components/ui/TeamNameWithFlag';
import Tooltip from 'app/src/components/ui/Tooltip';

import { dayMonthYearFormat, teamCustomerSegments, engines, releaseStatuses } from 'shared/vars';
import _, { getReleaseStatusCopy } from 'shared/copy';

const GameThumbnail = styled.div`
	position: relative;
	border-radius: 4px;
	background: ${props => (props.url ? `url("${props.url}")` : props.theme.grey7)};
	background-size: cover;
	background-position: center center;
	height: 20px;
	width: 20px;
	margin-right: 8px;
	flex-shrink: 0;
`;

const GameName = styled.div`
	display: flex;
	align-items: center;
	overflow: hidden;
`;

const Game = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const GameLink = styled(Link)`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const Team = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const Subtitle = styled.div`
	font-size: 12px;
	color: ${props => props.theme.grey3};
`;

const Released = styled.div`
	display: flex;
	flex-direction: column;
`;

const Data = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledTooltip = styled(Tooltip)`
	width: max-content;
`;

const TimeSinceRelease = styled.div``;

const Stat = styled.div``;

const perPage = 10;

const filters = [
	{
		title: _`customerSegment`,
		field: 'pokifordevs_teams.customer_segment',
		type: 'multiselect',
		values: [...teamCustomerSegments],
	},
	{
		title: 'Release Status',
		field: 'dbt_p4d_meta_game.release_status',
		type: 'multiselect',
		values: releaseStatuses.map(v => {
			v.desc = _([getReleaseStatusCopy(v.value, true)]);
			return v;
		}),
	},
	{
		title: _`gameEngine`,
		field: 'pokifordevs_games.engine',
		type: 'multiselect',
		values: engines.map(engine => ({
			value: engine,
			desc: engine,
		})),
	},
];

const AdminGamesOverviewModule = React.memo(props => {
	const { startDate, endDate, deviceCategories } = props;

	const dispatch = useDispatch();

	const [page, setPage] = useURLState('gamesOverviewPage', 1);
	const [sort, setSort] = useURLState('gamesOverviewSort', { field: 'release_status_changed_at', direction: -1 });
	const [activeFilters, setActiveFilters] = useURLState('gamesOverviewFilters', {});

	const { rows: gamesOverview, included, total: totalItems } = useSelectDataByEpic(getGamesOverviewFull.id);
	const { rows: gamesOverviewTrend } = useSelectDataByEpic(getGamesOverviewTrend.id);
	const { rows: teams } = useSelectDataByEpic(getTeamsOverviewTeams.id);
	const { rows: games } = useSelectDataByEpic(getGamesOverviewGames.id);

	const getGamesOverviewStatus = useSelectApiStatus(getGamesOverviewTrend.id);

	const lastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_games_overview');

	useEffect(() => {
		dispatch(getTablesLastUpdatedAt.fetch());
		dispatch(getGamesOverviewGames.fetch());
	}, []);

	useEffect(() => {
		dispatch(getTeamsOverviewTeams.fetch({ from: startDate, to: endDate }));
		dispatch(getGamesOverviewFull.fetch({ from: startDate, to: endDate, page, perPage, sortField: sort.field, sortDirection: sort.direction, filters: activeFilters, currency: 'eur', deviceCategories }, ({ success$ }) => (
			success$.pipe(
				switchMap(({ payload: { result: { response } } }) => {
					const { rows } = response;
					const gameIds = lodash.uniq(lodash.map(rows, 'p4d_game_id'));

					if (gameIds.length === 0) return EMPTY;

					return of(getGamesOverviewTrend.fetch({ gameIds, from: startDate, to: endDate, currency: 'eur', deviceCategories }));
				}),
			)
		)));
	}, [sort, page, activeFilters, startDate, endDate, deviceCategories]);

	const dataPerGame = useMemo(() => {
		const groupBy = ['p4d_game_id'];

		const grouped = lodash.groupBy(gamesOverviewTrend, row => groupBy.map(field => row[field]).join('-'));

		return Object.values(grouped).map(values => {
			// These columns are (should be) same for all values
			const { p4d_game_id, release_status_changed_at, num_domains_live, team_id } = values[0];

			const row = { p4d_game_id, release_status_changed_at, num_domains_live, team_id, unique_dates: [] };

			const overTimeFields = [
				{
					field: 'gameplays',
					calculate: data => data.gameplays,
				},
				{
					field: 'developer_earnings',
					calculate: data => data.developer_earnings,
				},
				{
					field: 'conversion_to_play',
					calculate: data => measures.conversion_to_play.calculate(data),
				},
				{
					field: 'time_spent_per_dau',
					calculate: data => measures.time_spent_per_dau.calculate(data),
				},
				{
					field: 'ads_per_dau',
					calculate: data => measures.ads_per_dau.calculate(data),
				},
			];

			const daysBetweenStartAndEndDate = moment(endDate).diff(moment(startDate), 'day');

			values.forEach(data => {
				const daysFromEndDate = moment(endDate).diff(moment(data.date), 'day');

				overTimeFields.forEach(({ field, calculate }) => {
					row[`${field}_over_time`] = row[`${field}_over_time`] || Array(daysBetweenStartAndEndDate).fill(0);
					if (daysFromEndDate <= daysBetweenStartAndEndDate) {
						row[`${field}_over_time`][daysBetweenStartAndEndDate - daysFromEndDate] = calculate(data);
					}
				});
			});

			return row;
		});
	}, [gamesOverviewTrend, startDate, endDate]);

	const allFilters = useMemo(() => ([
		{
			title: _`teams`,
			field: 'dbt_p4d_games_overview.team_id',
			type: 'multiselect',
			values: teams.map(t => ({
				value: t.team_id,
				desc: t.name,
			})),
		},
		{
			title: _`games`,
			field: 'dbt_p4d_games_overview.p4d_game_id',
			type: 'multiselect',
			values: games.map(t => ({
				value: t.p4d_game_id,
				desc: t.title,
			})),
		},
		...filters,
	]), [filters, teams, games]);

	return (
		<Card
			title="Games Overview"
			noPadding
			buttons={[
				{
					id: 'overview-last-updated',
					type: 'info',
					disabled: !lastUpdatedAt,
					icon: GraphIcon,
					children: _`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(lastUpdatedAt)) }}`,
				},
			]}
		>
			<Table
				items={gamesOverview}
				isLoading={!getGamesOverviewStatus.done}
				compact
				page={page}
				perPage={perPage}
				totalItems={totalItems}
				setPagination={setPage}
				sortField={sort.field}
				sortDirection={sort.direction}
				setSort={setSort}
				filters={allFilters}
				activeFilters={activeFilters}
				setActiveFilters={setActiveFilters}
				columns={[
					{
						title: 'Released',
						longTitle: 'Release Status changed at',
						content: ({ item }) => {
							if (!item.release_status_changed_at) return null;

							const releaseDate = moment(item.release_status_changed_at);
							const daysSinceRelease = moment().diff(releaseDate, 'day');
							const weeksSinceRelease = moment().diff(releaseDate, 'week');
							const monthsSinceRelease = moment().diff(releaseDate, 'month');
							const yearsSinceRelease = moment().diff(releaseDate, 'year');

							let humanReadable = <TimeSinceRelease>{daysSinceRelease} day{daysSinceRelease !== 1 ? 's' : ''} ago</TimeSinceRelease>;

							if (yearsSinceRelease >= 2) {
								humanReadable = <TimeSinceRelease>{yearsSinceRelease} year{yearsSinceRelease !== 1 ? 's' : ''} ago</TimeSinceRelease>;
							} else if (monthsSinceRelease >= 2) {
								humanReadable = <TimeSinceRelease>{monthsSinceRelease} month{monthsSinceRelease !== 1 ? 's' : ''} ago</TimeSinceRelease>;
							} else if (weeksSinceRelease >= 2) {
								humanReadable = <TimeSinceRelease>{weeksSinceRelease} week{weeksSinceRelease !== 1 ? 's' : ''} ago</TimeSinceRelease>;
							}

							return (
								<Released>
									{humanReadable}
									<Subtitle>{releaseDate.format(`dddd, ${dayMonthYearFormat}`)}</Subtitle>
								</Released>
							);
						},
						sortField: 'release_status_changed_at',
					},
					{
						title: _`game`,
						width: 'max-content',
						content: ({ item }) => {
							const game = included.games[item.p4d_game_id]?.attributes;
							const team = included.teams[item.team_id]?.attributes;

							if (!game || !team) return _`gameOrTeamNotFound`;

							return (
								<Game>
									<GameName>
										<GameThumbnail url={getGameThumbnailUrl(game.thumbnail_url, 20)} />
										<GameLink to={`/${team.code}/games/${item.p4d_game_id}`}>{game.title}</GameLink>
									</GameName>
									<Subtitle>
										<StyledTooltip
											// eslint-disable-next-line no-underscore-dangle
											text={_([getReleaseStatusCopy(item._release_status, true, true)])}
											title="Live on"
											body={`${item.num_domains_live} domain${item.num_domains_live !== 1 ? 's' : ''}`}
										/>
									</Subtitle>
								</Game>
							);
						},
						sortField: 'title',
						defaultSortDirection: 1,
					},
					{
						title: _`team`,
						content: ({ item }) => {
							const team = included.teams[item.team_id]?.attributes;

							if (!team) return _`teamNotFound`;

							return (
								<Team>
									<TeamNameWithFlag team={team} />
									<Subtitle>{teamCustomerSegments.find(t => t.value === team.customer_segment)?.desc}</Subtitle>
								</Team>
							);
						},
						sortField: 'name',
						defaultSortDirection: 1,
					},
					{
						title: 'GP/D',
						longTitle: 'Gameplays per day',
						content: ({ item }) => {
							const dataOverTime = dataPerGame.find(row => row.p4d_game_id === item.p4d_game_id)?.gameplays_over_time;

							return (
								<Data title="Gameplays per day">
									<Stat>
										{formatNumber(item.gameplays_per_day)}
									</Stat>
									<TinyChart
										type="area"
										width={80}
										height={15}
										data={dataOverTime}
									/>
								</Data>
							);
						},
						sortField: 'gameplays_per_day',
					},
					{
						title: 'DE/D',
						longTitle: 'Developer earnings per day',
						content: ({ item }) => {
							const dataOverTime = dataPerGame.find(row => row.p4d_game_id === item.p4d_game_id)?.developer_earnings_over_time;

							return (
								<Data title="Developer earnings per day">
									<Stat $isZero={item.developer_earnings_per_day <= 0.00}>
										€{formatNumber(item.developer_earnings_per_day, { allowDecimals: true })}
									</Stat>
									<TinyChart
										type="area"
										width={80}
										height={15}
										data={dataOverTime}
									/>
								</Data>
							);
						},
						sortField: 'developer_earnings_per_day',
					},
					{
						title: 'C2P',
						longTitle: 'Conversion to play',
						content: ({ item }) => {
							const dataOverTime = dataPerGame.find(row => row.p4d_game_id === item.p4d_game_id)?.conversion_to_play_over_time;

							return (
								<Data title="Conversion to play">
									<Stat>
										{Math.round((item.conversion_to_play) * 100)}%
									</Stat>
									<TinyChart
										type="area"
										width={80}
										height={15}
										data={dataOverTime}
									/>
								</Data>
							);
						},
						sortField: 'conversion_to_play',
					},
					{
						title: 'TS/DAU',
						longTitle: 'Time spent per daily active user',
						content: ({ item }) => {
							const dataOverTime = dataPerGame.find(row => row.p4d_game_id === item.p4d_game_id)?.time_spent_per_dau_over_time;

							return (
								<Data title="Time spent per daily active user">
									<Stat>
										{formatTime(item.time_spent_per_dau)}
									</Stat>
									<TinyChart
										type="area"
										width={80}
										height={15}
										data={dataOverTime}
									/>
								</Data>
							);
						},
						sortField: 'time_spent_per_dau',
					},
					{
						title: 'ADS/DAU',
						longTitle: 'Ads per daily active user',
						content: ({ item }) => {
							const dataOverTime = dataPerGame.find(row => row.p4d_game_id === item.p4d_game_id)?.ads_per_dau_over_time;

							return (
								<Data title="Ads per daily active user">
									<Stat $isZero={item.ads_per_dau <= 0.00}>
										{Number(item.ads_per_dau).toFixed(2)}
									</Stat>
									<TinyChart
										type="area"
										width={80}
										height={15}
										data={dataOverTime}
									/>
								</Data>
							);
						},
						sortField: 'ads_per_dau',
					},
				]}
			/>
		</Card>
	);
});

export default AdminGamesOverviewModule;
