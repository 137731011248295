import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ignoreElements, tap } from 'rxjs';
import styled from 'styled-components';

import { regenerateUploadToken } from 'app/src/epics/game';

import ReplayIcon from 'shared/designTokens/icons/ui/small/ReplayIcon';

import Card from 'app/src/components/ui/Card';
import CopyToClipboard from 'app/src/components/CopyToClipboard';

import _ from 'shared/copy';
import { smallMedia } from 'shared/vars';

const GameUploadTokenContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	height: 48px;
	padding: 0 16px;

	border-radius: 8px;
	background: ${props => props.theme.static.grey1};
	color: ${props => props.theme.static.grey7};
`;

const GameUploadTokenActions = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const GameUploadToken = styled.div`
	width: 100%;
	display: flex;
	align-items: center;

	overflow-x: scroll;

	${smallMedia} {
		width: fit-content;
		overflow: auto;
	}
`;

const GameUploadTokenDescription = styled.p`
	margin: 0 0 16px 0;
`;

const GameUploadTokenTitle = styled.h4`
	margin: 0 0 8px 0;
`;

const GameUploadTokenRemindersList = styled.ul`
	margin: 0 0 16px 0;
	list-style-type: disc;
	color: ${props => props.theme.grey3};
`;

const GameUploadTokenActionWrapper = styled.div`

	${props => props.$disabled && `
		opacity: 0.5;
		pointer-events: none;
	`}

	svg {
		vertical-align: bottom;
		
		[fill] {
			fill: ${props => props.theme.static.grey7};
		}

		&:hover {
			[fill] {
				fill: ${props => props.theme.grey5};
			}
		}
	}
`;

const StyledReplayIcon = styled(ReplayIcon)`
	cursor: pointer;
`;

const GameUploadTokenModule = props => {
	const { game } = props;

	const dispatch = useDispatch();

	const [uploadToken, setUploadToken] = useState('');

	const handleRegenerateToken = () => {
		dispatch(regenerateUploadToken.fetch({ gameId: game.id }, ({ success$ }) => (
			success$.pipe(
				tap(({ payload: { result: { response } } }) => setUploadToken(response.upload_token)),
				ignoreElements(),
			)
		)));
	};

	return (
		<Card title="Game Upload Token">
			<>
				<GameUploadTokenDescription dangerouslySetInnerHTML={{ __html: _`gameUploadTokenDescription` }} />
				<GameUploadTokenTitle>{_`important`}:</GameUploadTokenTitle>
				<GameUploadTokenRemindersList>
					<li dangerouslySetInnerHTML={{ __html: _`gameUploadTokenCopy` }} />
					<li dangerouslySetInnerHTML={{ __html: _`gameUploadTokenShare` }} />
				</GameUploadTokenRemindersList>
			</>
			<GameUploadTokenContainer>
				<GameUploadToken> {uploadToken || ''} </GameUploadToken>
				<GameUploadTokenActions>
					<GameUploadTokenActionWrapper title="Generate upload token">
						<StyledReplayIcon onClick={handleRegenerateToken} />
					</GameUploadTokenActionWrapper>
					<GameUploadTokenActionWrapper $disabled={!uploadToken}>
						<CopyToClipboard text={uploadToken} />
					</GameUploadTokenActionWrapper>
				</GameUploadTokenActions>
			</GameUploadTokenContainer>
		</Card>
	);
};

export default GameUploadTokenModule;
