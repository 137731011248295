import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ignoreElements, tap } from 'rxjs';

import history from 'app/history';
import Header from 'app/src/components/ui/Header';
import AdminNavigation from 'app/src/components/ui/AdminNavigation';
import Navigation from 'app/src/components/ui/Navigation';
import PageLoader from 'app/src/components/PageLoader';
import ToastContainer from 'app/src/components/ToastContainer';
import { useSelectIsDateRangeInputOpen, useSelectIsDragging, useSelectNavigationIsActive } from 'app/src/selectors/client';
import { useSelectPermissions, useSelectUser } from 'app/src/selectors/user';
import { minWidth, maxWidth, headerHeight, isMobile, largePadding, motionOut, motionSpeed02, smallMedia, smallPadding, mediumPadding, mediumMedia } from 'shared/vars';
import { useSelectActiveTeam, useSelectTeamByCode } from 'app/src/selectors/team';
import { useSelectIsAuthenticated } from 'app/src/selectors/session';
import { setCachedURLTeamCode, setVisitedTeam } from 'app/src/actions/session';
import { getTeamByCode } from 'app/src/epics/team';
import { toggleNavigation } from 'app/src/actions/client';
import checkPermissions from 'app/src/utils/checkPermissions';
import { adminMenuButtons } from 'app/src/components/AdminMenu';

const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: ${props => props.theme.grey9};
	display: flex;
	overflow: hidden;
`;

const InnerContainer = styled.div`
	position: relative;
	padding: ${mediumPadding}px ${smallPadding}px;
	margin-top: ${headerHeight};
	height: calc(100% - ${headerHeight});
	z-index: 0;
	overflow: auto;
	flex-grow: 1;

	${smallMedia} {
		padding: ${mediumPadding}px;
	}

	${mediumMedia} {
		padding: ${largePadding}px;
	}

	${props => (props.navigationActive && isMobile) && `
		overflow: hidden;
	`}

	${props => (props.isDragging || props.isDateRangeInputOpen) && `
		z-index: 3;
	`};

	${props => props.isDateRangeInputOpen && `
		overflow: hidden;

		${smallMedia} {
			overflow: auto;
		}
	`}

	${props => (!props.hasNavigation || isMobile) && `
	margin-left: 0;
	`}

	${props => !props.hasHeader && `
	margin-top: 0;
	`}
`;

const WidthDeciderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	min-width: ${minWidth};
	max-width: ${maxWidth};
	flex-grow: 1;
`;

const Overlay = styled.div`
	pointer-events: none;
	opacity: 0;
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: ${props => `${props.theme.static.denimBlue}BF`};
	z-index: 3;
	transition: opacity ${motionSpeed02} ${motionOut};

	${props => props.navigationActive && `
	pointer-events: all;
	opacity: 1;
	`}
`;

const ChildrenWrapper = styled.div`
	width: 100%;
	height: 100%;
`;

const PageContainer = React.memo(props => {
	const { authRequired, forceLoader, teamScope, isAdminPage, children } = props;

	const dispatch = useDispatch();

	const { teamCode } = useParams();
	const user = useSelectUser();
	const urlTeam = useSelectTeamByCode(teamCode);
	const isAuthenticated = useSelectIsAuthenticated();
	const activeTeam = useSelectActiveTeam();
	const permissions = useSelectPermissions();

	const canSeeAdminNavigation = useMemo(() => adminMenuButtons.some(button => {
		if (button.children) {
			return button.children.some(child => checkPermissions(permissions, child.needsPermissions));
		}

		if (checkPermissions(permissions, button.needsPermissions)) {
			return true;
		}

		return false;
	}), [permissions]);

	useEffect(() => {
		// Retrieve data if we're logged in
		if (isAuthenticated && user && teamScope) {
			if ((!urlTeam || urlTeam.code !== teamCode) && teamCode) {
				dispatch(getTeamByCode.fetch(teamCode, ({ error$ }) => (
					error$.pipe(
						tap(() => history.push(`/${checkPermissions(permissions, [['can_read_all_games']]) ? 'admin' : activeTeam ? activeTeam.code : user.team.code}`)),
						ignoreElements(),
					)
				)));
			}
		}
	}, [user, urlTeam, teamCode, isAuthenticated, teamScope]);

	useEffect(() => {
		if (checkPermissions(permissions, [['can_read_all_teams']]) && activeTeam) {
			dispatch(setVisitedTeam({ team: activeTeam }));
		}
	}, [permissions, activeTeam]);

	useEffect(() => {
		dispatch(setCachedURLTeamCode({ code: urlTeam ? urlTeam.code : null }));
	}, [urlTeam]);

	const closeNavigation = () => {
		dispatch(toggleNavigation({ force: false }));
	};

	const isLoading = forceLoader || (authRequired && (!user || !activeTeam));
	const hasNavigation = authRequired && user;
	const hasHeader = authRequired && user && isMobile;
	const navigationActive = useSelectNavigationIsActive();
	const isDateRangeInputOpen = useSelectIsDateRangeInputOpen();
	const isDragging = useSelectIsDragging();

	return (
		<Container>
			{isLoading || (teamScope && !urlTeam) ? (
				<PageLoader />
			) : (
				<>
					{hasNavigation && (
						canSeeAdminNavigation && isAdminPage ? (
							<AdminNavigation />
						) : (
							<>
								{canSeeAdminNavigation && <AdminNavigation collapsed />}
								<Navigation adminMenuActive={canSeeAdminNavigation} />
							</>
						))}
					{hasHeader && <Header />}
					<InnerContainer
						hasNavigation={hasNavigation}
						hasHeader={hasHeader}
						adminMenuActive={canSeeAdminNavigation && !isAdminPage}
						navigationActive={navigationActive}
						isDateRangeInputOpen={isDateRangeInputOpen}
						isDragging={isDragging}
					>
						<WidthDeciderContainer>
							{(!authRequired || user) && children && (
								<ChildrenWrapper>
									{children}
								</ChildrenWrapper>
							)}
						</WidthDeciderContainer>
						{isMobile && hasNavigation && <Overlay navigationActive={navigationActive} onClick={closeNavigation} />}
						<ToastContainer />
					</InnerContainer>
				</>
			)}
		</Container>
	);
});

export default PageContainer;
