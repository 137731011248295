import React from 'react';
import styled from 'styled-components';

const LinkStyledButtonWrapper = styled.button`
	padding: 0;
	border: none;
	background: none;
	cursor: pointer;

	color: ${props => props.theme.pokiBlue};
	font-size: 16px;
	font-weight: 400;

	&:hover {
		color: ${props => props.theme.pokiHover};
		text-decoration: none;
		background: none;
	}

	&:focus {
		outline: none;
	}

	&:disabled {
		color: ${props => props.theme.grey5};
		cursor: not-allowed;
	}

	${props => props.negative && `
		color: ${props.theme.rose1};
		
		&:hover {
			color: ${props.theme.rose3};
		}
	`}
`;

const LinkStyledButton = ({ children, className, onClick, disabled, negative, type = 'button' }) => {
	let clickHandler;

	if (onClick) {
		clickHandler = e => {
			e.stopPropagation();
			onClick(e);
		};
	}

	return (
		<LinkStyledButtonWrapper
			className={className}
			onClick={clickHandler}
			disabled={disabled}
			negative={negative}
			type={type}
		>
			{children}
		</LinkStyledButtonWrapper>
	);
};

export default LinkStyledButton;
