import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import CloseIcon from 'shared/designTokens/icons/ui/small/CloseIcon';

import { isMobile } from 'shared/vars';
import { closeModal } from 'app/src/actions/client';

const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: ${isMobile ? '16px' : '40px'};
	background: ${props => `${props.theme.static.denimBlue}BF`}};
	display: flex;
	justify-content: center;
	z-index: 4;
	overflow-y: scroll;
	scrollbar-width: none;

	${props => (props.alignment === 'top' ? `
	align-items: flex-start;
	` : `
	align-items: center;
	`)}
`;

const InnerContainer = styled.div`
	position: relative;
	margin: ${isMobile ? '0' : '10px'}};
	padding: ${isMobile ? '24px' : '40px'}};
	background: ${props => props.theme.pureWhite};
	border-radius: 8px;
	box-shadow: ${props => props.theme.boxShadowSmall};
	justify-content: center;
	align-items: center;
	width: 540px;
	border-radius: 8px;

	${props => props.alignment === 'center' && `
	margin-top: auto;
	margin-bottom: auto;
	`}

	${props => props.wide && `
	width: 760px;
	`}
`;

const ExitButton = styled.div`
	position: absolute;
	right: 12px;
	top: 12px;
	cursor: pointer;

	[fill] {
		fill: ${props => props.theme.static.grey5};
	}

	${props => (props.disabled ? `
	cursor: not-allowed;

	[fill] {
		fill: ${props.theme.grey7};
	}
	` : `
	&:hover {
		[fill] {
			fill: ${props.theme.rose1};
		}
	}
	`)}
`;

export const EXIT_SOURCES = {
	CLICK_OUTSIDE: 0,
	ESC_KEY: 1,
	SUBJECT: 2,
	EXIT_BUTTON: 3,
};

const Modal = props => {
	const { alignment = 'center', wide, className, children, canExit, exit$, disableOverlayClose = false, hideExitButton = false, ignoreFocusCheck = false, isLoading = false } = props;

	const overlayEl = useRef();
	const dispatch = useDispatch();

	const attemptExit = useCallback(
		source => {
			if (canExit && !canExit(source)) return;

			dispatch(closeModal());
		},
		[canExit],
	);

	const clickExitHandler = useCallback(e => {
		if (e.target !== overlayEl.current || disableOverlayClose) return;

		attemptExit(EXIT_SOURCES.CLICK_OUTSIDE);
	}, [attemptExit]);

	const keyExitHandler = useCallback(e => {
		if (e.keyCode !== 27) return; // ESC key

		if (!ignoreFocusCheck && ['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName)) {
			// Don't close if user is typing in an input, just unfocus the input
			document.activeElement.blur();
			return;
		}

		attemptExit(EXIT_SOURCES.ESC_KEY);
	}, [attemptExit]);

	useEffect(() => {
		window.addEventListener('keydown', keyExitHandler);

		let subscription;
		if (exit$) {
			subscription = exit$.subscribe(() => {
				attemptExit(EXIT_SOURCES.SUBJECT);
			});
		}

		return () => {
			window.removeEventListener('keydown', keyExitHandler);

			if (subscription) {
				subscription.unsubscribe();
			}
		};
	}, [keyExitHandler, exit$]);

	const onClickExitButton = () => attemptExit(EXIT_SOURCES.EXIT_BUTTON);

	return (
		<Overlay onMouseDown={clickExitHandler} ref={overlayEl} alignment={alignment}>
			{!isLoading && (
				<InnerContainer className={className} wide={wide ? 1 : 0} alignment={alignment}>
					{!hideExitButton && (
						<ExitButton onClick={onClickExitButton} disabled={canExit && !canExit(EXIT_SOURCES.EXIT_BUTTON)}>
							<CloseIcon />
						</ExitButton>
					)}
					{children}
				</InnerContainer>
			)}
		</Overlay>
	);
};

export default Modal;
