import React from 'react';
import styled, { css } from 'styled-components';

import ChevronDownIcon from 'shared/designTokens/icons/ui/tiny/ChevronDownIcon';

import formatNumber from 'app/src/utils/formatNumber';
import formatTime from 'app/src/utils/formatTime';

const Difference = styled.div`
	font-size: 14px;
	color: ${props => props.theme.grey3};
	text-transform: lowercase;
	display: flex;
	flex-wrap: wrap;
	height: 24px;
`;

const PreviousValue = styled.span`
	font-weight: bold;
	white-space: nowrap;
	display: inline-flex;
	align-items: center;
	height: 1em;
	margin-top: 5px;
`;

const PreviousDifferenceValue = styled.span`
	font-weight: bold;
	white-space: nowrap;
	display: inline-flex;
	align-items: center;
	height: 1em;
	margin-top: 5px;
	color: ${props => props.theme.rose1};

	svg {
		path {
			fill: ${props => props.theme.rose1};
		}
	}

	${props => props.$increase && css`
	color: ${props.theme.green1};

	svg {
		transform: scale(1, -1);

		path {
			fill: ${props.theme.green2};
		}
	}
	`}
`;

const ValueContainer = styled.div`
	display: flex;
	align-items: center;

	@media (max-width: 550px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const ValueDesc = styled.div`
	color: ${props => props.theme.denimBlue};
	line-height: 20px;
`;

const Decimals = styled.span`
	line-height: 0;
	position: relative;
	font-size: 18px;
	bottom: 12px;

	${props => !props.isLarge && `
		font-size: 16px;
		bottom: 10px;
	`}
`;

const Value = styled.div`
	font-size: 36px;
	font-weight: bold;
	margin-bottom: 4px;

	${props => !props.isLarge && `
		font-size: 32px;
	`}

	@media (max-width: 550px) {
		margin-bottom: 12px;
	}
`;

const Description = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 12px;

	@media (max-width: 550px) {
		align-items: flex-start;
		margin: 0;
	}
`;

const Container = styled.div`
	& + * {
		margin-top: 12px;
	}
`;

const Stat = props => {
	const { className, value, valueDesc, previousAsAbsolute, previous, previousDesc, isTime, isCurrency, isSmallNumber, isLarge = true, hasDescription = true } = props;

	let formatter = num => <>{formatNumber(num, { allowDecimals: false, shorten: true })}</>;

	if (isCurrency) {
		formatter = num => {
			const parts = formatNumber(num, { allowDecimals: true }).split('.');
			const currency = isCurrency === 'usd' ? '$' : '€';

			return <>{currency}{parts[0]}<Decimals isLarge={isLarge}>.{parts[1]}</Decimals></>;
		};
	} else if (isTime) {
		formatter = num => formatTime(num, true, true);
	} else if (isSmallNumber) {
		formatter = num => Number(num).toFixed(2);
	}

	const hasPrevious = typeof previous !== 'undefined';

	let differenceNumber = 0;
	if (hasPrevious) {
		differenceNumber = (Number(value) / Number(previous)) * 100;
		if (differenceNumber < 100) {
			differenceNumber = 100 - differenceNumber;
		} else {
			differenceNumber -= 100;
		}
	}
	let differenceText;

	if (differenceNumber === Infinity) differenceText = 'infinity';
	else if (differenceNumber > 999) differenceText = '999%+';
	else if (differenceNumber > 100) differenceText = `${differenceNumber.toFixed(0)}%`;
	else if (Number.isNaN(differenceNumber)) differenceText = '0.00%';
	else differenceText = `${differenceNumber.toFixed(2)}%`;

	return (
		<Container className={className}>
			{typeof value !== 'undefined' && value !== null && !Number.isNaN(value) && (
				<ValueContainer>
					<Value isLarge={isLarge}>{formatter(value)}</Value>
					{hasDescription && (
						<Description>
							<ValueDesc>{valueDesc}</ValueDesc>
							<Difference>
								{hasPrevious && (
									<>
										{differenceText === '0.00' && !previousAsAbsolute ? (
											'No change'
										) : (
											previousAsAbsolute ? (
												<PreviousValue>
													{previous}
												</PreviousValue>
											) : (
												<PreviousDifferenceValue $increase={value >= previous}>
													<ChevronDownIcon /> {differenceText}
												</PreviousDifferenceValue>
											)
										)}
										{previousDesc && <>&nbsp;{previousDesc}</>}
									</>
								)}
							</Difference>
						</Description>
					)}
				</ValueContainer>
			)}
		</Container>
	);
};

Stat.defaultProps = {
	formatter: num => num,
};

export default Stat;
