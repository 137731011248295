import styled, { css } from 'styled-components';

import { lightColors, tinyMedia, smallMedia, mediumMedia, largeMedia } from 'shared/vars';

export const Title = css`
	font: 700 32px/1.25 Torus,Montserrat,Arial,sans-serif;
`;

export const H1 = styled.h1`
	${Title}
	margin: 0;
	${smallMedia} {
		font-size: 40px;
		line-height: 50px;
	}
	${largeMedia} {
		font-size: 52px;
		line-height: 64px;
	}
`;

export const H2 = styled.h2`
	${Title}
	margin: 0;
	${smallMedia} {
		font-size: 40px;
		line-height: 50px;
	}
	${largeMedia} {
		font-size: 52px;
		line-height: 64px;
	}
`;

export const H3 = styled.h3`
	${Title}
	font-size: 24px;
	line-height: 30px;
	${smallMedia} {
		font-size: 30px;
		line-height: 40px;
	}
	${largeMedia} {
		font-size: 40px;
		line-height: 50px;
	}
`;

export const P = styled.p`
	margin: 0;

	${({ $small }) => $small && `
		font-size: 14px;
	`}
`;

export const Container = styled.div`
	position: relative;
	background: ${lightColors.pureWhite};
	color: ${lightColors.denimBlue};
	padding-bottom: 6px;
	font-size: 18px;
	line-height: 32px;
	${largeMedia} {
		font-size: 22px;
		line-height: 36px;
	}
`;

export const Page = styled.div`
	position: relative;
	z-index: 1;
`;

export const SectionContainer = styled.div`
	position: relative;
	padding: 0 25px;
	max-width: 1536px;
	margin: 0 auto;
	height: 100%;
	padding:
	${tinyMedia} {
		padding-left: 50px;
		padding-right: 50px;
	}
	${smallMedia} {
		padding-left: 64px;
		padding-right: 64px;
	}
	${largeMedia} {
		padding-left: 72px;
		padding-right: 72px;
	}
`;

export const BackgroundImage = styled.div`
	flex-shrink: 0;
	background-image: url(${props => props.backgroundUrl});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom left;
	display: block;
	width: 100%;
	height: 200px;
	margin-bottom: 15px;
	position: relative;
	${props => props.center && `
		background-position: bottom center;
	`}
`;

export const Hr = styled.hr`
	border-color: ${props => props.theme.grey7};
	border-style: solid;
	margin: 64px 0;
`;

export const Paragraph = styled(P)`
	font-size: 14px;
	line-height: 1.7;
	color: ${props => props.color};
	${props => props.small && `
		font-size: 12px;
	`}
	${props => props.large && `
		font-size: 16px;
	`}
	${props => props.extraLarge && `
		font-size: 18px;
		line-height: 1.5;

		${mediumMedia} {
			font-size: 22px;
			line-height: 1.6;
		}
	`}
	${props => props.capitalize && `
		text-transform: capitalize;
	`}
	${props => props.align && `
		text-align: ${props.align};
	`}
	${props => props.spaced && `
		padding-top: 24px;
	`}
	strong {
		color: ${props => props.theme.grey3};
	}
`;

export const TextGap = styled.div`
	margin-top: 16px;
`;
