import React from 'react';
import styled from 'styled-components';

import GraphIcon from 'shared/designTokens/icons/ui/medium/GraphIcon';
import { smallMedia } from 'shared/vars';
import _ from 'shared/copy';

const Container = styled.div`
	background: ${props => props.theme.grey7};
	display: flex;
	max-width: 444px;
	width: 100%;
	padding: 32px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
	border-radius: 4px;
`;

const UpperContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;

	${smallMedia} {
		flex-direction: row;
		order: 1;
	}
`;

const LeftContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	flex: 1 0 0;
`;

const StyledGraphIcon = styled(GraphIcon)`
	width: 64px;
	height: 64px;

	${smallMedia} {
		width: 108px;
		height: 108px;
		order: 2;
	}
`;

const Level3Step3 = () => (
	<Container>
		<UpperContainer>
			<StyledGraphIcon />
			<LeftContainer>
				{_`webFitTestResultsDesc`}
			</LeftContainer>
		</UpperContainer>
	</Container>
);

export default Level3Step3;
