const getExternalFileByURL = async (url, handleFile, handleError) => {
	if (url && typeof url === 'string') {
		try {
			const response = await fetch(url);

			const blob = await response.blob();
			const file = new File([blob], url.split('/').pop(), { type: 'application/zip' });

			// Pass the file to the handler function
			handleFile(file);

			localStorage.removeItem('externalFileURL');
		} catch (error) {
			handleError(error);
			console.error('Error fetching file:', error);
		}
	}
};

export default getExternalFileByURL;
