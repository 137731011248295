import { ignoreElements, tap } from 'rxjs';
import { ofType } from 'redux-observable';
import history from 'app/history';

import { createAuthorizedApiEpic, sortPageFilterQuery } from 'app/src/utils/api';
import { selectTeamById } from 'app/src/selectors/team';
import dataFormatter from 'app/src/utils/dataFormatter';
import getApiUrl from 'app/src/utils/getApiUrl';

export const listTeams = createAuthorizedApiEpic(
	'team/list_teams',
	(callApi, { page = 1, perPage = 1000000000, sortField, sortDirection, filters }) => callApi({
		url: getApiUrl('devs', `teams?${sortPageFilterQuery({ sortField, sortDirection, page, perPage, filters })}`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const listAllTeams = createAuthorizedApiEpic(
	'team/list_all_teams',
	callApi => callApi({
		url: getApiUrl('devs', `teams?${sortPageFilterQuery({ sortField: 'name', sortDirection: 1, page: 1, perPage: 999999999 })}`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const getTeamById = createAuthorizedApiEpic(
	'team/get_team_by_id',
	(callApi, teamId) => callApi({
		url: getApiUrl('devs', `teams/${teamId}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
	}),
);

export const getTeamByCode = createAuthorizedApiEpic(
	'team/get_team_by_code',
	(callApi, teamCode) => callApi({
		url: getApiUrl('devs', `teams/${teamCode}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
	}),
);

export const getTeamNewRevenueShares = createAuthorizedApiEpic(
	'team/get_new_revenue_shares',
	(callApi, { teamId }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/relationships/new-ad-revenue-shares`),
		method: 'GET',
	}),
);

export const addTeamNewRevenueShares = createAuthorizedApiEpic(
	'team/add_new_revenue_shares',
	(callApi, { teamId, gameId, shares }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/relationships/new-ad-revenue-shares`),
		method: 'POST',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
		body: JSON.stringify({
			data: {
				type: 'new_ad_revenue_shares',
				attributes: {
					...shares,
					game_id: gameId,
				},
			},
		}),
	}),
);

export const getTeamRevenueShares = createAuthorizedApiEpic(
	'team/get_revenue_shares',
	(callApi, { teamId, page = 1, perPage = 1000000000, sortField, sortDirection }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/relationships/ad-revenue-shares?${sortPageFilterQuery({ sortField, sortDirection, page, perPage })}`),
		method: 'GET',
	}),
);

export const getTeamBillingSettings = createAuthorizedApiEpic(
	'team/get_billing_settings',
	(callApi, { teamId }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/billing`),
		method: 'GET',
	}),
);

export const addTeamRevenueShares = createAuthorizedApiEpic(
	'team/add_revenue_shares',
	(callApi, { teamId, shares }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/relationships/ad-revenue-shares`),
		method: 'POST',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
		body: JSON.stringify({
			data: shares.map(data => dataFormatter.serialize({
				stuff: {
					type: 'ad_revenue_shares',
					...data,
				},
			}).data),
		}),
	}),
);

export const setTeamRevenueSharesToZero = createAuthorizedApiEpic(
	'team/set_revenue_shares_to_zero',
	(callApi, { teamId }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/relationships/ad-revenue-shares/@setzero`),
		method: 'POST',
	}),
);

export const listUsersByTeamId = createAuthorizedApiEpic(
	'team/get_users_by_team_id',
	(callApi, { teamId, sortField, sortDirection, page = 1, perPage = 1000000000 }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/relationships/users?${sortPageFilterQuery({ sortField, sortDirection, page, perPage })}`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const removeUserFromTeam = createAuthorizedApiEpic(
	'team/remove_user_from_team',
	(callApi, { teamId, userId }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/relationships/users/${userId}`),
		method: 'DELETE',
	}),
);

export const createTeam = createAuthorizedApiEpic(
	'team/create_team',
	(callApi, { name, code, customerSegment, userIds = [] }) => callApi({
		url: getApiUrl('devs', 'teams'),
		method: 'POST',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'teams',
				name,
				code,
				customer_segment: customerSegment,
				users: userIds.map(id => ({
					type: 'users',
					id,
				})),
				relationshipNames: ['users'],
			},
		})),
	}),
);

export const patchTeam = createAuthorizedApiEpic(
	'team/patch_team',
	(callApi, { teamId, data }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}`),
		method: 'PATCH',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				id: teamId,
				type: 'teams',
				...data,
			},
		})),
	}),
);

export const getWiseRequirements = createAuthorizedApiEpic(
	'team/wise_requirements',
	(callApi, { teamId }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/_wise-requirements`),
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
	}),
);

export const updateWiseRquirements = createAuthorizedApiEpic(
	'team/update_wise_requirements',
	(callApi, { data, teamId }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/_wise-requirements`),
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: data,
	}),
);

export const saveWiseDetails = createAuthorizedApiEpic(
	'team/wise_details',
	(callApi, { data, teamId }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/_wise-details`),
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: data,
	}),
);

export const getWiseDetails = createAuthorizedApiEpic(
	'team/get_wise_details',
	(callApi, { teamId }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/_wise-details`),
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
	}),
);

// This epic is used to redirect to the new team settings page if the team code has changed
export function patchGameEpic(action$, state$) {
	return action$.pipe(
		ofType(patchTeam.success.type),
		tap(({ payload: { result: { response: { data } } } }) => {
			const { id, attributes: { code } } = data;
			const currentTeam = selectTeamById(state$.value, id);

			if (currentTeam?.code !== code) {
				history.push(`/${code}/settings/`);
			}
		}),
		ignoreElements(),
	);
}
