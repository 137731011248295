import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { getCurrentMonth, getCurrentYear, getIsFirstWeekOfMonth } from 'app/src/utils/dates';

import GridContainer from 'app/src/components/ui/GridContainer';
import MessageBox, { MessageBoxIntents } from 'app/src/components/ui/MessageBox';
import Card from 'app/src/components/ui/Card';
import TeamSettingsBillingSubPage from 'app/src/components/pages/settings/TeamSettingsBillingSubPage';

import _ from 'shared/copy';

const TeamSettingsBillingPageContainer = props => {
	const { team } = props;

	const [hasInvoices, setHasInvoices] = useState(false);

	const handleHasInvoices = value => {
		setHasInvoices(value);
	};

	return (
		<GridContainer cols={1}>
			<Helmet key={`TeamSettingsBillingSubPage-${team.code}`}>
				<title>Billing - {team.name} - Poki for Developers</title>
			</Helmet>
			{hasInvoices && getIsFirstWeekOfMonth() && (
				<MessageBox
					unclosable
					intent={MessageBoxIntents.NEGATIVE}
					title={_`paymentCycleInProgress`}
					description={(
						<span dangerouslySetInnerHTML={{ __html: _`paymentCycleInProgressText${{ currentMonth: getCurrentMonth(), currentYear: getCurrentYear() }}` }} />
					)}
				/>
			)}
			<Card
				title={_`billingInformation`}
				description={_`billingInformationDesc`}
			>
				<TeamSettingsBillingSubPage team={team} handleHasInvoices={handleHasInvoices} />
			</Card>
		</GridContainer>
	);
};

export default TeamSettingsBillingPageContainer;
