import React from 'react';
import styled from 'styled-components';
import Select, { components } from 'react-select';

import _ from 'shared/copy';

const ReactSelectInputFieldset = styled.fieldset`
	position: relative;
	min-width: 0;
	max-width: 500px;
	margin-bottom: 16px;

	${props => props.small && `
	margin-bottom: 0;
	`}
`;

const ReactSelectInputContainer = styled(Select)`
	width: 100%;
	font-weight: normal;

	.Select__control {
		padding: 0 8px;
		height: 48px;
		width: 100%;

		background: ${props => props.theme.grey7};
		border: 1px solid ${props => props.theme.grey5};
		border-radius: 8px;

		font-size: 16px;
		color: ${props => props.theme.denimBlue};

		&--is-focused {
			outline: none;
			border: 2px solid ${props => props.theme.pokiBlue};

			.Select__indicator {
				color: ${props => props.theme.pokiBlue};
			}
		}

		&:hover {
			border: 1px solid ${props => props.theme.grey5};
			background: ${props => props.theme.grey9};
		}
	}

	.Select__option {
		color: ${props => props.theme.denimBlue};
		background: ${props => props.theme.pureWhite};

		&:hover {
			background: ${props => props.theme.grey7};
		}

		&--is-disabled {
			background: ${props => props.theme.pureWhite};

			&:hover {
				background: ${props => props.theme.pureWhite};
			}

			&:active {
				background: ${props => props.theme.pureWhite};
			}
		}

		&--is-selected {
			background: ${props => props.theme.grey7};
			color: ${props => props.theme.denimBlue};
		}

		&:active {
			background: ${props => props.theme.grey9};
		}
	}

	.Select__single-value {
		color: ${props => props.theme.denimBlue};
	}

	
	.Select__menu {
		border-radius: 8px;
		box-shadow: ${props => props.theme.boxShadowSmall};
		background: ${props => props.theme.pureWhite};
		font-size: 16px;
		padding: 0;
	}

	.Select__placeholder {
		color: ${props => props.theme.grey3};
	}

	.Select__indicator-separator {
    	display: none;
  	}
`;

const ReactSelectInputDescription = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	min-height: 18px;
`;

const ReactSelectInputError = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;

	& + & {
		margin-top: 0;
	}
`;

const ReactSelectInputRequired = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	position: absolute;
	top: 4px;
	right: 0;
`;

const ReactSelectInputLabel = styled.label`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};

	${ReactSelectInputContainer} {
		margin-top: 4px;
	}
`;

const ReactSelectInputOptionLabel = styled.div`
	display: flex;
	align-items: center;
`;

const ReactSelectInputOptionThumbnail = styled.img`
	width: 24px;
	height: 24px;
	margin-right: 8px;
	border-radius: 4px;
`;

const ReactSelectInputOptionIcon = styled.div`
	width: 24px;
	height: 24px;
	margin-right: 8px;
`;

const CustomOption = props => {
	const { data: { icon, label }, type } = props;

	const isOption = type === 'option';
	const ComponentType = isOption ? components.Option : components.SingleValue;

	return (
		<ComponentType {...props}>
			<ReactSelectInputOptionLabel>
				{typeof icon === 'string' ? (
					<ReactSelectInputOptionThumbnail src={icon} alt={label} />
				) : (
					<ReactSelectInputOptionIcon>
						{icon}
					</ReactSelectInputOptionIcon>
				)}
				{label}
			</ReactSelectInputOptionLabel>
		</ComponentType>
	);
};

const ReactSelectInput = props => {
	const { className, small, name, description, label, options, required, placeholder, errors, value, onChange } = props;

	const preparedOptions = options.map(option => ({
		value: option.value,
		label: option.label,
		icon: option.icon,
		isDisabled: option.isDisabled || false,
	}));

	const input = (
		<ReactSelectInputContainer
			classNamePrefix="Select"
			components={{ Option: CustomOption, SingleValue: CustomOption }}
			options={preparedOptions}
			required={required}
			placeholder={placeholder}
			isSearchable={false}
			errors={errors}
			value={preparedOptions.find(option => option.value === value)}
			onChange={val => onChange(val.value)}
		/>
	);

	return (
		<ReactSelectInputFieldset className={className} small={small}>
			{required && <ReactSelectInputRequired>{_`required`}</ReactSelectInputRequired>}
			{label ? (
				<ReactSelectInputLabel htmlFor={name}>{label}{input}</ReactSelectInputLabel>
			) : input}
			{errors.length > 0 ? errors.map(err => <ReactSelectInputError key={err}>{err}</ReactSelectInputError>) : (
				description && (
					<ReactSelectInputDescription>{description}</ReactSelectInputDescription>
				)
			)}
		</ReactSelectInputFieldset>
	);
};

export default ReactSelectInput;
