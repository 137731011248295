import React, { useCallback, useEffect, useState } from 'react';
import { useSelectApiStatus } from '@poki/rx-api';
import { useDispatch } from 'react-redux';
import { Subject, ignoreElements, tap } from 'rxjs';
import styled from 'styled-components';

import { registerModal } from 'app/src/modals';
import { patchTeam } from 'app/src/epics/team';
import { useSelectUser } from 'app/src/selectors/user';

import Modal, { EXIT_SOURCES } from 'app/src/components/ui/Modal';
import Button from 'app/src/components/ui/Button';
import Link from 'app/src/components/Link';

import { ultraTinyMedia } from 'shared/vars';

const AgreeButton = styled(Button)`	
	margin-top: 20px;
	float: right;
	width: fit-content;

	${ultraTinyMedia} {
		max-width: 100%;
	}
`;

const AcceptPrivacyAddendumModal = () => {
	const [exit$] = useState(new Subject());
	const [confirmCountdown, setConfirmCountdown] = useState(5);
	const user = useSelectUser();

	const dispatch = useDispatch();
	const patchTeamStatus = useSelectApiStatus(patchTeam.id);

	useEffect(() => {
		if (confirmCountdown < 0) return;

		const timeout = setTimeout(() => {
			setConfirmCountdown(confirmCountdown - 1);
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [confirmCountdown]);

	const onConfirm = useCallback(() => {
		if (confirmCountdown > 0) return;
		if (!user?.team) return;

		dispatch(
			patchTeam.fetch(
				{
					teamId: user.team.id,
					data: {
						read_privacy_addendum: 'accepted',
					},
				},
				({ success$ }) => success$.pipe(
					tap(() => {
						exit$.next();
					}),
					ignoreElements(),
				),
			),
		);

		exit$.next();
	}, [user?.team, confirmCountdown > 0]);

	return (
		<Modal
			hideExitButton
			canExit={source => source === EXIT_SOURCES.SUBJECT}
			exit$={exit$}
		>
			<h2>Update to our Privacy Compliance</h2>
			As part of our ongoing commitment to our users’ privacy and in compliance with evolving privacy regulations, we have updated our privacy terms. For this purpose, we’ve drafted an updated version of our Privacy Addendum. The addendum helps to ensure the highest level of privacy compliance.<br />
			<br />
			All developers will need to agree to this addendum moving forward. Please review the our <Link to="/privacy-addendum" target="_blank">Privacy Addendum</Link> and click “Agree” below to accept these new terms. If you have any questions or concerns, please email <a href="mailto:developersupport@poki.com">developersupport@poki.com</a>.<br />
			<br />
			<i>By clicking below, you confirm that you are authorized to represent the developer Poki contracted within this matter.</i><br />
			<AgreeButton
				onClick={onConfirm}
				disabled={patchTeamStatus.pending || confirmCountdown > 0}
			>
				{patchTeamStatus.pending ? 'Accepting...' : confirmCountdown > 0 ? `Please read the above (${confirmCountdown})` : 'I have read and agree to this Privacy Addendum'}
			</AgreeButton>
		</Modal>
	);
};

registerModal('accept-privacy-addendum', AcceptPrivacyAddendumModal);
