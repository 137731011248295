import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useSelectPermissions, useSelectUser } from 'app/src/selectors/user';
import { useSelectExternalFileURL, useSelectIsAuthenticated, useSelectRedirectPathname } from 'app/src/selectors/session';
import { authorize } from 'app/src/epics/session';
import getParameterByName from 'app/src/utils/getParameterByName';
import checkPermissions from 'app/src/utils/checkPermissions';

const AuthPage = () => {
	const dispatch = useDispatch();
	const { provider } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const user = useSelectUser();
	const permissions = useSelectPermissions();
	const isAuthenticated = useSelectIsAuthenticated();
	const redirectPathname = useSelectRedirectPathname();
	const externalFileURL = useSelectExternalFileURL();

	const [exchangeToken, setExchangeToken] = useState(getParameterByName('exchange_token', location.search));
	const errorCode = getParameterByName('code', location.search);

	useEffect(() => {
		if (errorCode) {
			// Navigate doesn't work here because it doesn't support state anymore. That's why the useNavigate hook is used.
			navigate('/signin', { state: { errorCode } });
		}
	}, [errorCode, navigate]);

	useEffect(() => {
		setExchangeToken(getParameterByName('exchange_token', location.search));
	}, [location.search]);

	useEffect(() => {
		if (exchangeToken && !isAuthenticated) {
			dispatch(authorize.fetch({ exchangeToken, provider }));
		}
	}, [exchangeToken, isAuthenticated, dispatch, provider]);

	if (!exchangeToken) {
		return (
			<Navigate to={{ pathname: '/' }} />
		);
	}

	if (isAuthenticated && user) {
		const isAdmin = checkPermissions(permissions, [['can_read_all_games']]);

		if (redirectPathname && redirectPathname !== '/' && redirectPathname !== '/admin') {
			return (
				<Navigate to={{ pathname: redirectPathname }} />
			);
		}

		if (user?.team && !user.team.verified && user.team.read_privacy_addendum === 'not-accepted') {
			return (
				<Navigate to={{ pathname: '/complete-signup' }} />
			);
		}

		if (!isAdmin && user?.team && !user.team.verified && externalFileURL) {
			return (
				<Navigate to={{ pathname: `/${user.team.code}/games/create` }} />
			);
		}

		return (
			<Navigate to={{ pathname: `/${isAdmin ? 'admin' : user.team.code}` }} />
		);
	}

	return (
		<Helmet key="AuthPage">
			<title>Authenticating... - Poki for Developers</title>
		</Helmet>
	);
};

export default AuthPage;
