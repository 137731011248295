import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Button from 'app/src/components/ui/Button';
import TextInput from 'app/src/components/input/TextInput';
import { setGameChecklistStatus } from 'app/src/actions/session';
import { selectLastUploadedVersionByGame } from 'app/src/selectors/game';
import { getGameById } from 'app/src/epics/game';
import { pushEvent } from 'app/src/utils/tracking';
import _ from 'shared/copy';

const StyledTextInput = styled(TextInput)`
	width: 100%;
`;

const Container = styled.div`
	background: ${props => props.theme.grey7};
	display: flex;
	width: 100%;
	max-width: 444px;
	padding: 32px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
	border-radius: 4px;
`;

const Level2Step1 = ({ onSubmitCallback, disabled, game }) => {
	const dispatch = useDispatch();
	const version = selectLastUploadedVersionByGame(game?.versions);

	const onTestClick = () => {
		window.open(`https://poki.com/en/preview/${game.id}/${version.id}`, '_blank');

		dispatch(setGameChecklistStatus({ gameId: game.id, level: 'playtest', step: 1, status: 'completed' }));
		onSubmitCallback();

		pushEvent('gameCreateFlow', 'testClick');
	};
	const inputText = version
		? `https://poki.com/en/preview/${game.id}/${version.id}`
		: _`loading`;

	const buttonDisabled = disabled || !version;

	// Keep refreshing while the upload is in still processing.
	// Don't refresh if there is no game yet (possible when the user just clicks on Level 2 without uploading a game).
	useEffect(() => {
		if (version || !game?.id) {
			return;
		}

		const int = setInterval(() => {
			dispatch(getGameById.fetch(game.id));
		}, 1000);

		return () => clearInterval(int);
	}, [game]);

	return (
		<Container>
			{disabled ? _`afterUploadShare`
				: (
					<>
						{_`canAlreadyShare`}
						<StyledTextInput readonly value={inputText} allowCopy />
						<Button onClick={onTestClick} disabled={buttonDisabled}>{_`testYourGame`}</Button>
					</>
				)}
		</Container>
	);
};
export default Level2Step1;
