import React from 'react';
import styled from 'styled-components';

import Button from 'app/src/components/ui/Button';
import { H2, H3, P, BackgroundImage } from 'site/src/components/styled';

import { smallMedia, mediumMedia, largeMedia } from 'shared/vars';
import { spawnEmoji } from 'shared/utils/emojiHover';

const ButtonWrapper = styled(Button)``;

const MarketingLinksSection = styled.section`
	padding: 40px 5%;
	position: relative;
	max-width: 1536px;
	margin: 0 auto;

	${H2} {
		text-align: center;
	}

	${P} {
		color: #5D6B84;
	}
`;

const MarketingCards = styled.div`
	margin-top: 40px;
	margin-bottom: 50px;

	${smallMedia} {
		padding: 50px 0;
		margin: 0;
	}

	${mediumMedia} {
		display: flex;
		justify-content: space-evenly;
		padding: 50px 0;
	}

	${ButtonWrapper} {
		margin-top: 25px;
		align-self: center;
	}

	${H3} {
		font-size: 24px;
		line-height: 28px;
		margin-bottom: 16px;

		${smallMedia} {
			font-size: 28px;
			line-height: 32px;
		}
	}
`;

const MarketingCard = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-bottom: 50px;
	width: 100%;

	> img {
		height: 48px;

		${smallMedia} {
			height: 80px;
		}
	}

	${mediumMedia} {
		padding-left: 25px;
		padding-right: 25px;
	}

	${largeMedia} {
		width: 33.33%;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const handleSpawnEmoji = evt => {
	spawnEmoji(evt.target);
};

const MarketingLinks = () => (
	<>
		<MarketingLinksSection>
			<MarketingCards>
				<MarketingCard>
					<H3>About Poki</H3>
					<P>
						Poki is on a mission to create the ultimate online playground.
					</P>
					<ButtonWrapper marketingButton secondary onMouseEnter={handleSpawnEmoji} openInNewTab to="https://about.poki.com">Get to know us</ButtonWrapper>
				</MarketingCard>
				<MarketingCard>
					<H3>Developer Guide</H3>
					<P>
						Kickstart your journey with our guide on best practices for web.
					</P>
					<ButtonWrapper marketingButton secondary onMouseEnter={handleSpawnEmoji} to="/guide">Let&apos;s build</ButtonWrapper>
				</MarketingCard>
				<MarketingCard>
					<H3>Our Blog</H3>
					<P>
						Find out more about web games and what it&apos;s like to work with us.
					</P>
					<ButtonWrapper marketingButton secondary onMouseEnter={handleSpawnEmoji} to="https://blog.poki.com/" openInNewTab>Follow our journey</ButtonWrapper>
				</MarketingCard>
			</MarketingCards>
		</MarketingLinksSection>
		<BackgroundImage backgroundUrl="/images/footer_coin.svg" />
	</>
);

export default MarketingLinks;
