import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { dayMonthYearFormat } from 'shared/vars';
import getVersionLabel from 'app/src/utils/getVersionLabel';

export const selectGame = createSelector(
	state => state.game.games,
	(state, id) => id,

	(games, id) => {
		if (!games?.[id]) return null;

		return games[id].data;
	},
);

export const selectGameErrors = createSelector(
	state => state.game.errors,
	(state, id) => id,

	(errors, id) => errors[id] || [],
);

export const selectGameErrorsPerMinute = createSelector(
	state => state.game.errorsPerMinute,
	(state, id) => id,

	(errors, id) => errors[id] || [],
);

export const selectPlayerFeedbackForGame = createSelector(
	state => state.game.playerFeedback,
	(state, id) => id,

	(feedback, id) => (feedback[id] || { data: [], meta: { total: 0 } }),
);

export function selectLastUploadedVersionByGame(versions) {
	if (!versions || versions.length === 0) {
		return null;
	}

	const filteredVersions = versions.filter(v => !!v.created_at && !v.archived_at && v.state === 'done');

	if (filteredVersions.length === 0) {
		return null;
	} else if (filteredVersions.length === 1) {
		return filteredVersions[0];
	}

	return filteredVersions.reduce((a, b) => {
		if (new Date(a.created_at) > new Date(b.created_at)) {
			return a;
		}
		return b;
	});
}

export const selectGameURL = createSelector(
	game => game,
	game => game?.content_metadata?.urls,

	(game, urls) => {
		if (!urls || Object.keys(urls).length === 0) {
			const version = selectLastUploadedVersionByGame(game.versions);
			if (version) {
				return {
					url: `https://poki.com/en/preview/${game.id}/${version.id}`,
					isPreview: true,
				};
			}

			return null;
		}

		if (urls['poki.com/en']) {
			return {
				url: urls['poki.com/en'],
				isPreview: false,
			};
		}

		return {
			url: Object.values(urls)[0],
			isPreview: false,
		};
	},
);

export const selectGameAnnotations = createSelector(
	state => state.game.annotations,

	annotations => annotations,
);

export const selectGameVersionsForPlaytests = createSelector(
	selectGame,

	game => {
		if (!game || !game.versions) {
			return [];
		}

		const nonArchivedVersions = game.versions.filter(v => !v.archived_at && v.state === 'done') || [];
		const sortedVersions = nonArchivedVersions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

		return sortedVersions.map(v => ({
			value: v.id,
			desc: `${getVersionLabel(v)} - ${moment(v.created_at * 1000).format(dayMonthYearFormat)}`,
			inspector_checklist: v.inspector_checklist,
		}));
	},
);

export const selectGamesByTeamId = (state, teamId) => (state.game.gamesByTeamId[teamId] ? state.game.gamesByTeamId[teamId] : []);
export const selectAllVersionListByGameId = (state, gameId) => (state.game.allVersionListByGameId[gameId] ? state.game.allVersionListByGameId[gameId] : {});
export const selectActiveVersionListByGameId = (state, gameId) => (state.game.activeVersionListByGameId[gameId] ? state.game.activeVersionListByGameId[gameId] : {});
export const selectNonArchivedVersionListByGameId = (state, gameId) => (state.game.nonArchivedVersionListByGameId[gameId] ? state.game.nonArchivedVersionListByGameId[gameId] : {});
export const selectArchivedVersionListByGameId = (state, gameId) => (state.game.archivedVersionListByGameId[gameId] ? state.game.archivedVersionListByGameId[gameId] : {});

export const useSelectGamesByTeamId = teamId => useSelector(state => selectGamesByTeamId(state, teamId));
export const useSelectGame = (id, options) => useSelector(state => selectGame(state, id), options);
export const useSelectGameErrors = id => useSelector(state => selectGameErrors(state, id));
export const useSelectGameErrorsPerMinute = id => useSelector(state => selectGameErrorsPerMinute(state, id));
export const useSelectPlayerFeedbackForGame = id => useSelector(state => selectPlayerFeedbackForGame(state, id));
export const useSelectGameAnnotations = () => useSelector(state => selectGameAnnotations(state));
export const useSelectAllVersionListByGameId = gameId => useSelector(state => selectAllVersionListByGameId(state, gameId));
export const useSelectActiveVersionListByGameId = gameId => useSelector(state => selectActiveVersionListByGameId(state, gameId));
export const useSelectNonArchivedVersionListByGameId = gameId => useSelector(state => selectNonArchivedVersionListByGameId(state, gameId));
export const useSelectArchivedVersionListByGameId = gameId => useSelector(state => selectArchivedVersionListByGameId(state, gameId));
export const useSelectGameVersionsForPlaytests = gameId => useSelector(state => selectGameVersionsForPlaytests(state, gameId));
