import formatTime from 'app/src/utils/formatTime';

const getPlaytimeRange = test => {
	if (!test) {
		return null;
	}

	const { engagement } = test;

	const playtime = Math.floor(engagement / 10) * 10;
	const playtimeRange = `${formatTime(playtime - 10, true, true)} - ${formatTime(playtime + 10, true, true)}`;

	return playtimeRange;
};

export default getPlaytimeRange;
