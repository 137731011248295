import React, { useRef } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import Star1 from 'shared/components/svg/symbols/Star1';
import Star2 from 'shared/components/svg/symbols/Star2';
import Star3 from 'shared/components/svg/symbols/Star3';
import Heart1 from 'shared/components/svg/symbols/Heart1';
import Heart2 from 'shared/components/svg/symbols/Heart2';
import Heart3 from 'shared/components/svg/symbols/Heart3';
import Block3 from 'shared/components/svg/symbols/Block3';
import Block1 from 'shared/components/svg/symbols/Block1';
import Block2 from 'shared/components/svg/symbols/Block2';
import Diamond1 from 'shared/components/svg/symbols/Diamond1';
import Diamond2 from 'shared/components/svg/symbols/Diamond2';
import Diamond3 from 'shared/components/svg/symbols/Diamond3';
import Coin3 from 'shared/components/svg/symbols/Coin3';
import Coin1 from 'shared/components/svg/symbols/Coin1';
import Coin2 from 'shared/components/svg/symbols/Coin2';

import { smallMedia, easings } from 'shared/vars';
import DashedLine from 'shared/components/svg/DashedLine';

const ChecklistStepContainer = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
	position: relative;

	${props => !props.active && `
		cursor: pointer;
	`}
`;

const CheckboxContainer = styled.div`
	display: flex;
	width: 36px;
	height: 52px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	z-index: 1;

	${smallMedia} {
		width: 80px;
	}
`;

const Checkbox = styled.div`
	max-width: 60px;
	max-height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${props => (props.checked ? '6px' : (props.current ? '10px' : '8px'))};

	border-radius: 50%;
	background: ${props => props.theme.grey7};

	${smallMedia} {
		padding: ${props => (props.checked ? '6px' : (props.current ? '12px' : '8px'))};
	}
`;

const Unchecked = styled.div`
	border-radius: 50%;
	background: ${props => (props.active ? props.theme.pokiBlue : props.theme.grey5)};

	${props => (props.current ? `
		width: 16px;
		height: 16px;
	` : `
		width: 12px;
		height: 12px;
	`)}
`;

const Frame = styled.div`
	display: flex;
	padding: 16px 0px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 1 0 0;
	height: ${props => (props.active ? `${props.frameHeight}px` : '72px')};
	overflow: hidden;
	transition: height 800ms ease; /* Fallback in case linear-easing is not supported */
	transition: height 800ms ${easings.figma};
	will-change: height;
`;

const Content = styled.div`
	opacity: ${props => (props.active ? 1 : 0)};
	transition: opacity 800ms ease; /* Fallback in case linear-easing is not supported */
	transition: opacity 800ms ${easings.figma};
	will-change: opacity;
`;

const Title = styled.h2`
	align-self: stretch;
	color: ${props => props.theme.denimBlue};
	font: 400 20px/20px "Proxima Nova";

	${props => props.active && `
		font-weight: 700;
	`}
`;

const StyledDashedLine = styled(DashedLine)`
	position: absolute;
	top: 20px;
	left: 14px;

	width: 6px;
	height: 100%;
	z-index: 0;

	pattern path {
      fill: ${props => props.theme.grey7};
    }

	${smallMedia} {
		left: 37px;
	}

	${ChecklistStepContainer}:last-child & {
		display: none;
	}
`;

const componentMap = {
	star: [
		<Star1 />,
		<Star2 />,
		<Star3 />,
	],
	heart: [
		<Heart1 />,
		<Heart2 />,
		<Heart3 />,
	],
	block: [
		<Block1 />,
		<Block2 />,
		<Block3 />,
	],
	diamond: [
		<Diamond1 />,
		<Diamond2 />,
		<Diamond3 />,
	],
	coin: [
		<Coin1 />,
		<Coin2 />,
		<Coin3 />,
	],
};

const getCheckedComponent = (checkType, randomCheckIndex) => componentMap[checkType][randomCheckIndex];

const ChecklistStep = props => {
	const { className, title, contentGenerator, onSubmitCallback, onClick, checked, current, checkType, active, disabled, game } = props;
	// persistantly store the random check index
	const randomCheckIndex = useRef(Math.floor(Math.random() * 3));

	const frameRef = useRef(null);
	const frameHeight = frameRef.current?.scrollHeight;

	const activateClick = active ? null : onClick;

	return (
		<ChecklistStepContainer className={className} onClick={activateClick} active={active}>
			<StyledDashedLine />
			<CheckboxContainer>
				<Checkbox checked={checked} current={current}>
					{checked ? getCheckedComponent(checkType, randomCheckIndex.current) : <Unchecked active={active} current={current} />}
				</Checkbox>
			</CheckboxContainer>
			<Frame ref={frameRef} active={active} frameHeight={frameHeight}>
				<Title active={active}>{title}</Title>
				<Content active={active}>
					{contentGenerator?.({ onSubmitCallback, disabled, game })}
				</Content>
			</Frame>
		</ChecklistStepContainer>
	);
};

ChecklistStep.propTypes = {
	className: PT.string,
	title: PT.string.isRequired,
	contentGenerator: PT.func.isRequired,
	checkType: PT.string.isRequired,
	checked: PT.bool,
	active: PT.bool,
	disabled: PT.bool,
	onSubmitCallback: PT.func.isRequired,
	onClick: PT.func.isRequired,
};

ChecklistStep.defaultProps = {
	className: null,
	checked: false,
	disabled: true,
	active: true,
};

export default ChecklistStep;
