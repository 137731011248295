import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { trackUserAndTeam, pushEvent, setEventContext, initMixpanel } from 'app/src/utils/tracking';
import { useSelectUser, useSelectIsLockedOut, useSelectPermissions } from 'app/src/selectors/user';
import { clearRedirectPathname, setExternalFileUpload, setExternalFileURL, setRedirectPathname } from 'app/src/actions/session';
import { useSelectIsAuthenticated, useSelectIsLoggingOutWithNewEmail, useSelectRedirectPathname } from 'app/src/selectors/session';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import checkPermissions from 'app/src/utils/checkPermissions';
import getParameterByName from 'app/src/utils/getParameterByName';

import PageContainer from 'app/src/components/PageContainer';
import GlitchPage from 'app/src/components/pages/GlitchPage';

import _ from 'shared/copy';

const EXTERNAL_PROJECTS_PATHS = ['/upload-defold'];

const RouteWrapper = props => {
	const { authRequired, needsPermissions, noContainer, children, routeConfig, eventPath } = props;

	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const isLoggingOutWithNewEmail = useSelectIsLoggingOutWithNewEmail();
	const redirectPathname = useSelectRedirectPathname();
	const isAuthenticated = useSelectIsAuthenticated();
	const isLockedOut = useSelectIsLockedOut();
	const permissions = useSelectPermissions();
	const activeTeam = useSelectActiveTeam();
	const user = useSelectUser();

	const [lastTrackedPageview, setLastTrackedPageview] = useState(null);

	// Don't log any pages that you don't need to be logged in for
	// Don't log any pages if you're not logged in yet - we can't guarantee consent
	const trackingAllowed = authRequired && user;

	const pageContainerProps = {
		authRequired,
		teamScope: routeConfig.path.startsWith('/:teamCode'),
		isAdminPage: location.pathname === '/admin' || location.pathname.startsWith('/admin/'),
	};

	useEffect(() => {
		if (trackingAllowed) {
			initMixpanel();
		}
	}, [trackingAllowed]);

	useEffect(() => {
		if (!trackingAllowed) return;

		trackUserAndTeam(user, activeTeam);

		setEventContext('page', eventPath);
	}, [eventPath, activeTeam?.id, user, trackingAllowed]); // Don't take the whole activeTeam object as this will change when team related data is fetched.

	useEffect(() => {
		if (!trackingAllowed) return;

		// Prevent sending duplicate pageviews because of non pathname changes
		if (lastTrackedPageview === location.pathname) return;

		// Track that pageview
		pushEvent('page', 'view');
		setLastTrackedPageview(location.pathname);
	}, [location.pathname, trackingAllowed]);

	useEffect(() => {
		if (EXTERNAL_PROJECTS_PATHS.includes(location.pathname)) {
			const url = getParameterByName('zipfile', location.search);
			const project = getParameterByName('project', location.search);

			if (!isAuthenticated && url) {
				// navigate to signin with url as query param and encoded
				navigate(`/signin/?project=${encodeURIComponent(project)}&externalURL=${encodeURIComponent(url)}`);
			}

			if (isAuthenticated && url) {
				dispatch(setExternalFileURL({ url }));
				dispatch(setExternalFileUpload({ isOpen: false }));
			}
		}
	}, [location.pathname, location.search, isAuthenticated, dispatch, navigate]);

	useEffect(() => {
		if (isAuthenticated) {
			const externalFileURL = localStorage.getItem('externalFileURL');

			if (externalFileURL) {
				dispatch(setExternalFileURL({ url: externalFileURL }));

				localStorage.removeItem('externalFileURL');
			}
		}
	}, [isAuthenticated, dispatch]);

	if (isLockedOut) {
		try {
			// Remove query params as they might contain auth tokens
			window.history.replaceState(null, '', window.location.href.split('?')[0]);
		} catch { }

		return (
			<GlitchPage
				title="HMMM"
				message={_`accountDisabledMessageTitle`}
				subMessage={_`accountDisabledMessageDesc`}
			/>
		);
	}

	if (isLoggingOutWithNewEmail || (isAuthenticated && (needsPermissions || authRequired) && (!user || !activeTeam))) {
		return (
			<PageContainer forceLoader {...pageContainerProps} />
		);
	}

	if (!user?.team?.verified && user?.team?.read_privacy_addendum === 'not-accepted' && location.pathname !== '/complete-signup') {
		return (
			<Navigate
				replace
				to={{ pathname: '/complete-signup' }}
			/>
		);
	}

	if ((!isAuthenticated && (needsPermissions || authRequired))) {
		// get window.location.pathname and store it in the state
		// so we can redirect back to it after login

		if (redirectPathname !== location.pathname) {
			dispatch(setRedirectPathname({ pathname: location.pathname }));
		}

		return (
			<Navigate
				replace
				to={{
					pathname: '/signin/',
					state: { error: 'Unauthorized' },
				}}
			/>
		);
	}

	if (!checkPermissions(permissions, needsPermissions)) {
		return (
			<Navigate
				to={{
					pathname: `/${checkPermissions(permissions, [['can_read_all_games']]) ? 'admin' : activeTeam ? activeTeam.code : user.team.code}`,
					state: { error: 'Unauthorized' },
				}}
			/>
		);
	}

	if (isAuthenticated && (needsPermissions || authRequired) && redirectPathname !== null) {
		dispatch(clearRedirectPathname());
	}

	if (noContainer) return children;

	return (
		<PageContainer {...pageContainerProps}>
			{children}
		</PageContainer>
	);
};

export default RouteWrapper;
