import React from 'react';
import styled from 'styled-components';

import { mediumMedia, isMobile, mediumPadding, largePadding, smallPadding } from 'shared/vars';

const Spacer = styled.div`
	width: 0px;
	height: 100%;
`;

const InnerContainer = styled.div`
	width: auto;
	display: grid;
	grid-template-columns: ${props => props.templateColumns};
	gap: 16px;

	${mediumMedia} {
		gap: 24px;
	}

	${props => !props.responsive && `
	@media (max-width: 600px) {
		grid-template-columns: auto;
	}
	`}

	${props => props.responsiveLayout && `
	> *:not(${Spacer}) {
		min-width: max-content;
	}
	`}
`;

const Container = styled.div`
	flex-shrink: 0;
	width: calc(100% + ${largePadding * 2}px);
	padding: 0 ${largePadding}px;
	margin: 0 -${largePadding}px;

	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 0;
		height: 0;
		scrollbar-width: none;
		scrollbar-height: none;
	}

	${isMobile && `
	padding: 0 ${smallPadding}px;
	margin: 0 -${smallPadding}px;
	width: calc(100% + ${smallPadding * 2}px);
	`}

	${props => (props.responsiveLayout ? `
	overflow-x: scroll;

	${props.cols === 3 ? `
	> ${InnerContainer} {
		width: 300%;
	}
	` : `
	> ${InnerContainer} {
		width: 200%;
	}
	`}
	` : '')};

	&:not(:last-child) {
		padding-bottom: ${smallPadding}px;

		${mediumMedia} {
			padding-bottom: ${mediumPadding}px;
		}
	}
`;

const GridContainer = props => {
	const { className, children, cols, responsive } = props;

	const responsiveLayout = isMobile && responsive && cols > 1;
	const templateColumns = `${Array(cols).fill('1fr').join(' ')} ${responsiveLayout ? '0px' : ''}`;

	return (
		<Container className={className} responsiveLayout={responsiveLayout} cols={cols}>
			<InnerContainer templateColumns={templateColumns} responsive={responsive}>
				{children}
				{responsiveLayout ? <Spacer /> : undefined}
			</InnerContainer>
		</Container>
	);
};

export default GridContainer;
