import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ignoreElements, map, merge, of, switchMap, tap } from 'rxjs';
import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import * as yup from 'yup';

import { getWiseDetails, getWiseRequirements, saveWiseDetails, updateWiseRquirements } from 'app/src/epics/team';
import { deleteKeysWithEmptyValuesFromObject } from 'app/src/utils/deleteKeysWithEmptyValuesFromObject';
import { startScreenShake } from 'app/src/actions/effects';
import { useSelectTeamIsCurrencyLocked } from 'app/src/selectors/team';
import { openModal, openToast, preventPageLeave } from 'app/src/actions/client';
import { useSelectPermissions } from 'app/src/selectors/user';
import { getIsFirstWeekOfMonth } from 'app/src/utils/dates';
import checkPermissions from 'app/src/utils/checkPermissions';
import flattenObject from 'app/src/utils/flattenObject';
import countries from 'app/src/utils/countries';

import Button, { ButtonText, ButtonTextContainer } from 'app/src/components/ui/Button';
import { ToastTypes } from 'app/src/components/ui/Toast';
import GridContainer from 'app/src/components/ui/GridContainer';
import RadioInput from 'app/src/components/input/RadioInput';
import SelectInput from 'app/src/components/input/SelectInput';
import TextInput from 'app/src/components/input/TextInput';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import TableLoader from 'app/src/components/ui/TableLoader';

import _ from 'shared/copy';

const TeamSettingsBillingSubPageStepWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const TeamSettingsBillingSubPageStepTitle = styled.h4`
	font-size: 16px;
	color: ${props => props.theme.denimBlue};
`;

const TeamSettingsBillingSubPageStep = styled.div`
	width: 32px;
	height: 32px;
	background: ${props => props.theme.dataGrey4};
	border-radius: 50%;
	line-height: 32px;
	font-weight: bold;
	color: #ffffff;
	text-align: center;
`;

const StatusContainer = styled.div`
	height: 180px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const PAYMENT_KEYS = ['currency', 'accountHolderName', 'country', 'legalType', 'paymentMethod'];
const ADDRESS_KEYS = ['city', 'postCode', 'firstLine', 'state'];
const INITIAL_FIELDS = {
	basicInformation: {
		legalType: {
			type: 'radio',
			name: 'legalType',
			label: _`legalTypeOfAccount`,
			refreshRequirementsOnChange: true,
			values: [
				{ value: 0, key: 'PRIVATE', desc: 'Private' },
				{ value: 1, key: 'BUSINESS', desc: 'Business' },
			],
			required: true,
		},
		accountHolderName: {
			name: 'accountHolderName',
			label: _`billingName`,
			placeholder: Math.random() < 0.5 ? _`placeholderNameA` : _`placeholderNameB`,
			required: true,
		},
		billingEmail: {
			name: 'billingEmail',
			label: _`billingEmail`,
			placeholder: 'email@email.com',
			required: true,
		},
		country: {
			type: 'select',
			name: 'country',
			label: _`billingCountry`,
			placeholder: _`selectACountry`,
			values: countries.map(c => ({ value: c.iso_2_code, desc: c.name })),
			refreshRequirementsOnChange: true,
			required: true,
		},
		currency: {
			type: 'select',
			name: 'currency',
			label: _`preferredCurrency`,
			placeholder: _`preferredCurrency`,
			values: [{ value: 'EUR', desc: _`euro` }, { value: 'USD', desc: _`usd` }],
			refreshRequirementsOnChange: true,
			required: true,
		},
	},
	paymentMethod: {},
	paymentDetails: {},
};

// Format data to match the API requirementss
const formatData = (values, fields) => {
	const { currency, accountHolderName, billingEmail, legalType, country, paymentMethod, email, vatNumber, publicNotes } = values;

	const addressDetails = {};
	const paymentDetails = {};

	Object.entries(values).forEach(([key, value]) => {
		if (ADDRESS_KEYS.some(relevantKey => key.includes(relevantKey))) {
			addressDetails[key] = value;
		} else if (!PAYMENT_KEYS.some(relevantKey => key.includes(relevantKey))) {
			paymentDetails[key] = value;
		}
	});

	const data = {
		type: fields.paymentMethod.values[paymentMethod]?.type,
		currency,
		accountHolderName,
		billingEmail,
		publicNotes,
		details: {
			...paymentDetails,
			legalType: fields.basicInformation.legalType.values[legalType]?.key,
			email,
			vatNumber,
			address: {
				country,
				...addressDetails,
			},
		},
	};

	const formattedData = deleteKeysWithEmptyValuesFromObject(data);

	return formattedData;
};

const baseSchema = yup.object({
	legalType: yup.number().oneOf([0, 1], _`fieldRequired`).required(),
	billingEmail: yup.string().required(_`fieldRequired`).email('Invalid email'),
	accountHolderName: yup.string().required(_`fieldRequired`),
	country: yup.string().oneOf(countries.map(c => c.iso_2_code), _`fieldRequired`),
	currency: yup.string().required(_`fieldRequired`),
});

// Validation schema needs to be extended based on which dynamic fields are present
const extendedSchema = fields => {
	const schema = {};

	Object.keys(fields.paymentDetails).forEach(key => {
		const { name, required, minLength, maxLength, type } = fields.paymentDetails[key];

		let fieldSchema = yup.string().nullable();
		const isRadioOrSelect = type === 'radio' || type === 'select';

		// For radio and select fields, we need to validate the value against the values array

		if (required) {
			if (!isRadioOrSelect) {
				fieldSchema = fieldSchema.required(_`fieldRequired`).max(maxLength).min(minLength);
			} else {
				fieldSchema = fieldSchema.oneOf(fields.paymentDetails[key].values.map(v => v.value), _`fieldRequired`).required(_`fieldRequired`);
			}
		}

		schema[name] = fieldSchema;
	});

	return baseSchema.shape(schema);
};

const renderFields = (input, apiStatus, control, errors, handleInputChange, isBlocked) => {
	if (Object.keys(input).length === 0) return null;

	const { type, name, label = '', values = [], placeholder = '', required = false, disabled = false, refreshRequirementsOnChange = false } = input;

	const fieldErrors = errors[name]?.message ? [errors[name]?.message] : [];

	switch (type) {
		case 'radio':
			return (
				<Controller
					key={name}
					name={name}
					defaultValue={values[0].value || 0}
					control={control}
					render={({ field }) => (
						<RadioInput
							{...field}
							renderAsRow
							label={label}
							values={values}
							placeholder={placeholder}
							onChange={e => handleInputChange(e, field, refreshRequirementsOnChange)}
							disabled={apiStatus.pending || apiStatus.error || isBlocked || disabled}
							errors={fieldErrors}
							required={required}
						/>
					)}
				/>
			);
		case 'select':
			return (
				<Controller
					key={name}
					name={name}
					control={control}
					render={({ field }) => (
						<SelectInput
							{...field}
							label={label}
							values={values}
							placeholder={placeholder}
							onChange={e => handleInputChange(e, field, refreshRequirementsOnChange)}
							disabled={apiStatus.pending || apiStatus.error || isBlocked || disabled}
							errors={fieldErrors}
							required={required}
						/>
					)}
				/>
			);
		default:
			return (
				<Controller
					key={name}
					name={name}
					control={control}
					render={({ field }) => (
						<TextInput
							{...field}
							label={label}
							placeholder={placeholder}
							onChange={e => handleInputChange(e, field, refreshRequirementsOnChange)}
							disabled={apiStatus.pending || apiStatus.error || isBlocked || disabled}
							errors={fieldErrors}
							required={required}
						/>
					)}
				/>
			);
	}
};

// These fields are supposed to be in the basicInformation so we need to exclude them from the paymentDetails
const EXCLUDE_FIELDS = ['address.country', 'legalType'];

const TeamSettingsBillingSubPage = ({ team, handleHasInvoices }) => {
	const { id: teamId, billing: { payment_method: initialPaymentMethod } } = team;
	const dispatch = useDispatch();

	const hasPaymentMethodChanged = useRef(false);
	const hasValidatedCurrency = useRef(false);

	const permissions = useSelectPermissions();
	const teamIsCurrencyLocked = useSelectTeamIsCurrencyLocked(team);
	const canEditBillingEntireMonth = checkPermissions(permissions, [['can_edit_billings_entire_month']]);
	const canChangeCurrencyFirstTime = checkPermissions(permissions, [['can_edit_team_currency_first_time']]);
	const canChangeCurrency = checkPermissions(permissions, [['can_edit_team_currency']]);
	const canEdit = checkPermissions(permissions, [['can_edit_all_teams', 'can_edit_owned_teams']]);

	const [wiseDetails, setWiseDetails] = useState({});
	const [formFields, setFormFields] = useState(INITIAL_FIELDS);
	const [fieldTypes, setFieldTypes] = useState([]);
	const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);

	const hasInvoices = wiseDetails?.hasInvoices;
	const isBlocked = (!canEditBillingEntireMonth && hasInvoices && getIsFirstWeekOfMonth()) || !canEdit;

	const getWiseDetailsStatus = useSelectApiStatus(getWiseDetails.id);
	const saveWiseDetailsStatus = useSelectApiStatus(saveWiseDetails.id);
	const wiseRequirementStatus = useSelectApiStatus(getWiseRequirements.id);
	const apiStatus = combinedApiStatus(getWiseDetailsStatus, wiseRequirementStatus);

	const {
		control,
		watch,
		getValues,
		setValue,
		handleSubmit,
		reset,
		formState: { errors, isDirty, isSubmitting },
	} = useForm({
		defaultValues: { paymentMethod: 0, accountType: 0 },
		resolver: yupResolver(extendedSchema(formFields)),
		shouldUnregister: true,
	});

	const paymentMethod = watch('paymentMethod');

	const handleSetFields = types => {
		if (types.length === 0) return;

		const filteredFields = types[paymentMethod || 0]?.fields?.filter(f => !EXCLUDE_FIELDS.includes(f.group[0].key));

		if (filteredFields.some(f => f.group[0].key === 'accountType')) {
			// Remove accountType from the formFields state and unshift it to the beginning of the array
			const accountType = filteredFields.find(f => f.group[0].key === 'accountType');

			filteredFields.splice(filteredFields.indexOf(accountType), 1);
			filteredFields.unshift(accountType);
		}

		// Set non-dynamic fields to the formFields state
		// Both paymentMethod and paymentDetails can change based on some information like: country, legalType, etc.
		setFormFields(prev => ({
			...prev,
			basicInformation: {
				...prev.basicInformation,
				legalType: {
					...prev.basicInformation.legalType,
					values: [
						...prev.basicInformation.legalType.values.map(v => ({ ...v, disabled: isBlocked })),
					],
				},
				currency: {
					...prev.basicInformation.currency,
					disabled: isBlocked || (!initialPaymentMethod && !canChangeCurrencyFirstTime) || (initialPaymentMethod && !canChangeCurrency) || teamIsCurrencyLocked,
				},
			},
			paymentMethod: {
				type: 'radio',
				name: 'paymentMethod',
				values: types.map((t, index) => ({ value: index, desc: t.title, type: t.type, disabled: isBlocked })),
			},
			paymentDetails: filteredFields.reduce((acc, curr) => {
				const { key, name, type, valuesAllowed, required, minLength, maxLength, refreshRequirementsOnChange } = curr.group[0];

				let fieldName = name;
				let keyName = key;
				let values = valuesAllowed;

				if (key === 'accountType' || key === 'address.state') {
					values = valuesAllowed.map(v => ({ value: v.key, desc: v.name }));
				}

				if (key.includes('address')) {
					keyName = key.replace('address.', '');
				}

				if (types[paymentMethod]?.type === 'paypal' && key === 'email') {
					fieldName = 'Paypal email';
				}

				// Get only necessay information from a field to populate the formFields state
				const formattedField = {
					name: keyName,
					key,
					type,
					values,
					defaultValue: wiseDetails[keyName],
					label: fieldName,
					placeholder: name,
					refreshRequirementsOnChange,
					required,
					minLength,
					maxLength,
				};

				return {
					...acc,
					[keyName]: formattedField,
				};
			}, {}),
		}));
	};

	const fetchWiseDetails = () => {
		dispatch(getWiseDetails.fetch({ teamId }, ({ success$, error$ }) => merge(
			success$.pipe(
				map(({ payload: { result: { response } } }) => {
					const legalTypeKey = response?.details?.legalType;
					const index = formFields.basicInformation?.legalType?.values?.findIndex(item => item.key === legalTypeKey);
					const flattenedResponse = flattenObject(response);

					setWiseDetails(flattenedResponse);
					handleHasInvoices(flattenedResponse?.hasInvoices);

					// Set initial value of non-dynamic fields, the ones in the first step (basicInformation)
					setValue('legalType', index !== -1 ? index : 0);
					setValue('billingEmail', response?.billingEmail || '');
					setValue('accountHolderName', response?.accountHolderName || '');
					setValue('country', response?.details?.address?.country || '');
					setValue('currency', response?.currency || '');
					setValue('publicNotes', response?.publicNotes || '');
				}),
			),
			error$.pipe(
				map(({ payload: { result: { response: { errors: [error] } } } }) => openToast({ body: error.title, toastType: ToastTypes.WARNING })),
			),
		)));
	};

	const fetchWiseRequirements = () => {
		dispatch(getWiseRequirements.fetch({ teamId }, ({ success$, error$ }) => (
			merge(
				success$.pipe(
					map(({ payload: { result: { response: { types } } } }) => {
						setFieldTypes(types);
						fetchWiseDetails();
					}),
				),
				error$.pipe(
					map(() => openToast({ body: _`errorPageMessage4`, toastType: ToastTypes.WARNING })),
				),
			)
		)));
	};

	const postUpdateWiseRequirements = data => {
		dispatch(updateWiseRquirements.fetch({ teamId, data }, ({ success$ }) => success$.pipe(
			map(({ payload: { result: { response: { types } } } }) => {
				setFieldTypes(types);
			}),
		)));
	};

	const postSaveWiseDetails = data => {
		dispatch(saveWiseDetails.fetch({ data, teamId: team.id }, ({ success$, error$ }) => (
			merge(
				success$.pipe(
					map(() => {
						dispatch(openToast({ body: _`billingSettingsSavedSuccessfully` }));
						reset({}, { keepValues: true });
						fetchWiseDetails();
					}),
				),
				error$.pipe(
					switchMap(({ payload: { result: { response: { errors: responseErrors } } } }) => of(
						responseErrors.map(error => dispatch(openToast({ body: error.title, toastType: ToastTypes.WARNING }))),
					)),
					tap(() => dispatch(startScreenShake())),
					ignoreElements(),
				),
			)
		)));
	};

	const handleInputChange = (e, field, refreshRequirementsOnChange) => {
		const fieldName = field.name;

		// update wiseDetails
		setWiseDetails(prev => ({
			...prev,
			[fieldName]: e.target.value,
		}));

		field.onChange(e);

		if (refreshRequirementsOnChange) {
			const data = formatData(getValues(), formFields);

			postUpdateWiseRequirements(data);
		}
	};

	useEffect(() => {
		fetchWiseRequirements();
	}, []);

	useEffect(() => {
		dispatch(preventPageLeave({ toggle: isDirty }));

		return () => {
			dispatch(preventPageLeave({ toggle: false }));
		};
	}, [isDirty]);

	useEffect(() => {
		handleSetFields(fieldTypes);
	}, [fieldTypes]);

	useEffect(() => {
		handleSetFields(fieldTypes);
	}, [paymentMethod]);

	useEffect(() => {
		if (hasInitiallyLoaded) return;

		if (apiStatus.done) {
			setHasInitiallyLoaded(true);
		}
	}, [apiStatus]);

	useEffect(() => {
		if (!wiseDetails.type || hasPaymentMethodChanged.current) return;

		const index = fieldTypes.findIndex(type => type.type === wiseDetails.type);

		setValue('paymentMethod', index !== -1 ? index : 0);
		hasPaymentMethodChanged.current = true;
	}, [wiseDetails]);

	useEffect(() => {
		const paymentDetails = Object.keys(formFields.paymentDetails);

		if (paymentDetails.length > 0) {
			// set initial value of dynamic fields
			paymentDetails.forEach(field => setValue(field, wiseDetails[field]));
		}
	}, [wiseDetails, formFields]);

	useEffect(() => {
		if (Object.keys(errors).length) {
			dispatch(startScreenShake());
		}
	}, [errors]);

	const handleSave = data => {
		const formattedData = formatData(data, formFields);

		if (!initialPaymentMethod && !hasValidatedCurrency.current) {
			dispatch(openModal({
				key: 'base-confirmation-modal',
				data: {
					title: `Are you sure you want to set the currency to ${getValues('currency')}?`,
					description: 'Before saving your billing settings, please confirm that the selected currency is correct.',
					onConfirm: () => {
						postSaveWiseDetails(formattedData);
						hasValidatedCurrency.current = true;
					},
				},
			}));

			return;
		}

		postSaveWiseDetails(formattedData);
	};

	return (
		!hasInitiallyLoaded ? (
			<StatusContainer>
				<TableLoader />
			</StatusContainer>
		) : (
			<form onSubmit={handleSubmit(handleSave)}>
				<TeamSettingsBillingSubPageStepWrapper>
					<TeamSettingsBillingSubPageStep>1</TeamSettingsBillingSubPageStep>
					<TeamSettingsBillingSubPageStepTitle>Basic information</TeamSettingsBillingSubPageStepTitle>
				</TeamSettingsBillingSubPageStepWrapper>
				<GridContainer cols={2}>
					{Object.keys(formFields.basicInformation).map(key => renderFields(formFields.basicInformation[key], apiStatus, control, errors, handleInputChange, isBlocked))}
				</GridContainer>
				<TeamSettingsBillingSubPageStepWrapper>
					<TeamSettingsBillingSubPageStep>2</TeamSettingsBillingSubPageStep>
					<TeamSettingsBillingSubPageStepTitle>Payment method</TeamSettingsBillingSubPageStepTitle>
				</TeamSettingsBillingSubPageStepWrapper>
				{renderFields(formFields.paymentMethod, apiStatus, control, errors, handleInputChange, isBlocked)}
				<TeamSettingsBillingSubPageStepWrapper>
					<TeamSettingsBillingSubPageStep>3</TeamSettingsBillingSubPageStep>
					<TeamSettingsBillingSubPageStepTitle>Payment details</TeamSettingsBillingSubPageStepTitle>
				</TeamSettingsBillingSubPageStepWrapper>
				<GridContainer cols={2}>
					{Object.keys(formFields.paymentDetails).map(key => renderFields(formFields.paymentDetails[key], apiStatus, control, errors, handleInputChange, isBlocked))}
					<Controller
						name="publicNotes"
						control={control}
						render={({ field }) => (
							<TextAreaInput
								{...field}
								label={_`notes`}
								placeholder={_`notesPlaceholder`}
								onChange={e => handleInputChange(e, field)}
								disabled={apiStatus.pending || apiStatus.error || isBlocked}
							/>
						)}
					/>
				</GridContainer>
				<ButtonTextContainer>
					<Button submit disabled={apiStatus.error || saveWiseDetailsStatus.pending || !isDirty || isSubmitting} primary>{saveWiseDetailsStatus.pending ? _`saving` : _`save`}</Button>
					<ButtonText warning={isDirty}>
						{saveWiseDetailsStatus.pending ? _`saving` : isDirty && (!apiStatus.error) ? _`unsavedChanges` : !saveWiseDetails ? _`loading` : ''}
					</ButtonText>
				</ButtonTextContainer>
			</form>
		)
	);
};

export default TeamSettingsBillingSubPage;
